/* @startCleanup encore */
import React from 'react';
import { AwardRibbon36x27, Gauge40x34, Stopwatch34x40 } from '@bamboohr/grim';
import { Banner } from '../components/banner';
import { SetupSummaryBannerProps } from '../types';

export function CoreImplementationBanner({
	biId,
	onClose,
	progress,
	progressNote,
	primaryUrl,
	title,
	subtitle,
	buttonText,
}: SetupSummaryBannerProps) {
	let bannerIcon;

	switch (title) {
		case 'Total Rewards':
			bannerIcon = <AwardRibbon36x27 />;
			break;
		case 'Project Pay':
			bannerIcon = <Stopwatch34x40 />;
			break;
		default:
			bannerIcon = <Gauge40x34 />;
	}

	return (
		<Banner
			SecondaryButtonProps={{
				children: buttonText,
				href: primaryUrl,
			}}
			icon={bannerIcon}
			onClose={onClose}
			primaryButtonBiId={biId ? `${biId}-coreImplementationSummaryBanner-viewLearningCourse` : null}
			secondaryButtonBiId={biId ? `${biId}-coreImplementationSummaryBanner-backToCoreImplementation` : null}
			subtitle={subtitle}
			title={title}
		/>
	);
}
/* @endCleanup encore */
