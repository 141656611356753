/******************************************** 
	JavaScript for Search Inputs

	CSS: /css/modules/SearchInput.mod.css
	
	Dependencies:  
	- jQuery: https://jquery.com/ 

********************************************/

import { isEnabled } from 'FeatureToggle.util';

const JADE_ENABLED = isEnabled('jade');

export default {

	name: 'Search Input Module',

	default: {
		input: JADE_ENABLED ? '.js-bhr-search-input' : '.bhrSearchInput input',
		close: JADE_ENABLED ? '.js-bhr-search-input-clear' : '.bhrSearchInput__clearSearch'
	},

	init: function () {
		this._registerEventListeners();
	},

	_registerEventListeners: function () {
		const { close, input } = this.default;

		$(close).on('click', function () {
			$(this).siblings(input).val('').trigger('keyup');
		});

		$(input).on('keyup', function () {
			let val = $(this).val().toLowerCase().trim();
			let closeEl = $(this).siblings(close);

			if (val === '') {
				closeEl.css({ 'opacity': '0', 'visibility': 'hidden' });
			} else {
				closeEl.css({ 'opacity': '1', 'visibility': 'visible' });
			}
		});
	}
};
