import {
	isEnabled,
} from 'FeatureToggle.util';

const FOOTER_SELECTOR = '.SiteFooter';
const FOOTER_OPEN_CLASS = 'SiteFooter--open';
const FOOTER_BUTTON_SELECTOR = '.SiteFooter__action';
const FOOTER_TEXT_BUTTON_CLASS = 'SiteFooter__action--link';
const BUTTON_PROCESSING_CLASS = 'fab-Button--processing';

let currentTop = null;
let actionsMinHeight;

function _getFooter() {
	return new Promise((resolve) => {
		const footer = document.querySelector(FOOTER_SELECTOR);

		if (footer) {
			resolve(footer);
		}
	});
}

function _stickFooterActions(actions, content, footer) {
	if (content && footer.classList.contains('SiteFooter--open')) {
		const contentRect = content.getBoundingClientRect();

		const maxTop = window.innerHeight - actionsMinHeight;
		const newTop = contentRect.bottom < maxTop ? contentRect.bottom + 'px' : null;

		if (currentTop !== newTop) {
			actions.style.top = newTop;
			currentTop = newTop;
		}
	} else {
		currentTop = null;
	}

	window.requestAnimationFrame(() => _stickFooterActions(...arguments));
}

function _toggleFooters(footer, isOpen) {
	Array.from(footer.children).forEach((entry) => {
		if (entry.classList.contains('SiteFooter__container')) {
			if (entry.classList.contains('SiteFooter__container--actions')) {
				entry.classList.toggle('hidden', !isOpen);
			} else {
				entry.classList.toggle('hidden', isOpen);
			}
		}
	})
}

if (isEnabled('jade')) {
	document.addEventListener('SiteFooterActions:enable', () => {
		_getFooter()
			.then((footer) => {
				Array.from(footer.querySelectorAll(`${ FOOTER_BUTTON_SELECTOR }:not(.${ FOOTER_TEXT_BUTTON_CLASS })`))
					.forEach(button => button.removeAttribute('disabled'));
			});
	});

	document.addEventListener('SiteFooterActions:disable', () => {
		_getFooter()
			.then((footer) => {
				Array.from(footer.querySelectorAll(`${ FOOTER_BUTTON_SELECTOR }:not(.${ FOOTER_TEXT_BUTTON_CLASS })`))
					.forEach(button => button.setAttribute('disabled', true));
			});
	});

	document.addEventListener('SiteFooterActions:toggleDisabled', () => {
		_getFooter()
			.then((footer) => {
				const someButtonIsDisabled = !!footer.querySelector(`${ FOOTER_BUTTON_SELECTOR }[disabled]`);
				if (someButtonIsDisabled) {
					document.dispatchEvent(new CustomEvent('SiteFooterActions:enable'));
				} else {
					document.dispatchEvent(new CustomEvent('SiteFooterActions:disable'));
				}
			});
	});

	document.addEventListener('SiteFooterActions:startProcessing', () => {
		_getFooter()
			.then((footer) => {
				Array
					.from(footer.querySelectorAll(FOOTER_BUTTON_SELECTOR))
					.forEach((button) => {
						button.setAttribute('disabled', true);
						if (!button.classList.contains(FOOTER_TEXT_BUTTON_CLASS)) {
							button.classList.add(BUTTON_PROCESSING_CLASS);
						}
					});
			});
	});

	document.addEventListener('SiteFooterActions:endProcessing', () => {
		_getFooter()
			.then((footer) => {
				Array
					.from(footer.querySelectorAll(FOOTER_BUTTON_SELECTOR))
					.forEach((button) => {
						button.removeAttribute('disabled');
						button.classList.remove(BUTTON_PROCESSING_CLASS);
					});
			});
	});

	document.addEventListener('SiteFooterActions:toggleProcessing', () => {
		_getFooter()
			.then((footer) => {
				const someButtonIsProcessing = !!footer.querySelector(`${ FOOTER_BUTTON_SELECTOR }[disabled], ${ FOOTER_BUTTON_SELECTOR }.${ BUTTON_PROCESSING_CLASS }`);
				if (someButtonIsProcessing) {
					document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
				} else {
					document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));
				}
			});
	});

	document.addEventListener('SiteFooter:toggle', () => {
		_getFooter()
			.then((footer) => {
				if (footer.classList.contains(FOOTER_OPEN_CLASS)) {
					document.dispatchEvent(new CustomEvent('SiteFooter:close'));
				} else {
					document.dispatchEvent(new CustomEvent('SiteFooter:open'));
				}
			});
	});

	document.addEventListener('SiteFooter:open', () => {
		_getFooter()
			.then((footer) => {
				_toggleFooters(footer, true);
				setTimeout(() => footer.classList.add(FOOTER_OPEN_CLASS));
			});
	});

	document.addEventListener('SiteFooter:close', () => {
		_getFooter()
			.then((footer) => {
				footer.classList.remove(FOOTER_OPEN_CLASS);
				setTimeout(() => _toggleFooters(footer, false), 300);
			});
	});

	document.addEventListener('DOMContentLoaded', () => {

		_getFooter()
			.then((footer) => {
				const actions = footer.querySelector('.SiteFooter__container--actions');

				if (actions) {
					actionsMinHeight = actions.getBoundingClientRect().height;

					const content = document.getElementById('content');

					if (footer.classList.contains('SiteFooter--open')) {
						_toggleFooters(footer, true);
					}

					_stickFooterActions(actions, content, footer);
				}
			});
	});

	document.addEventListener('click', ({ target }) => {
		const actionSelector = '[data-action^="SiteFooterAction:"]';
		target = target.closest(actionSelector);

		if (target && !target.disabled) {
			const [action, ...args] = target.dataset.action.split(':');

			switch (args[0]) {
				case 'submit':
					var id = args[1] || target.closest('footer').dataset.formId;
					var form = document.getElementById(id);

					if (form) {
						document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));

						const $form = $(form);

						// eslint-disable-next-line max-depth
						if ($form.data('validator')) {
							$form.on('invalid-form', () => {
								document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
							});
							$form.submit();
						} else {
							form.submit();
						}
					}
					break;
				case 'cancel':
					var [type, ...href] = args;

					href = href.length > 0 ? href.join(':') : false;

					if (href) {
						window.location = (new URL(href, window.location.origin)).href;
					} else {
						document.dispatchEvent(new CustomEvent('SiteFooter:close'));
					}

					break;
			}
		}
	});
}
