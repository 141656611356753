import {
	IdentityVerificationModal
} from './components/identity-verification-modal.react';
import mainSocket from 'main-socket.service';
import { useState, useRef } from 'react';

export function IdentityVerification() {

	const [verificationCode, setVerificationCode] = useState(null);

	const socketRef = useRef();

	const closeModal = () => {
		setVerificationCode(null);
	};

	mainSocket.subscribe({
		subject: 'identityVerification',
		events: {
			verify: ((code) => {
				setVerificationCode(code);
			}),
			close: (() => closeModal()),
		}
	}).then(result => {
		socketRef.current = result;
	});

	return (
		<IdentityVerificationModal
			isOpen={ verificationCode }
			onClose={ closeModal }
			verificationCode={ verificationCode }
		/>
	);
}
