import Comp from 'comp.deco';
import { isEnabled } from 'FeatureToggle.util';

const JADE_ENABLED = isEnabled('jade');

const ICONS = {
	'/inbox/time_off_requests/?assigned=me': 'fab-briefcase-clock-16x12',
	'/inbox/information_updates/?assigned=me': 'fab-info-circle-15x15',
	'/inbox/signatures/?assigned=me': 'fab-pen-nib-14x14',
	'/inbox/onboarding/?assigned=me': 'fab-clipboard-checklist-12x16',
	'/inbox/peer_feedback/?assigned=me': 'fab-people-14x12'
};

const TMPL = JADE_ENABLED ? {
	row: `
		<li class="ManageNavItemDropdown__item ManageNavItemDropdown__item--added js-manage-nav-added-item">
			<a class="ManageNavItemDropdown__itemLink" href="!*= href *!">
				<span class="ManageNavItemDropdown__itemText truncate">!*= label *!</span> <span class="ManageNavItemDropdown__itemCount">(!*= count *!)</span>
			</a>
		</li>
	`
} : {
	row: `
		<li class="down-list__subSection">
			<a href="!*= href *!">
				<span class="down-list__subSection-name truncate">!*= label *!</span> <span class="count">(!*= count *!)</span>
			</a>
		</li>
	`
};

let _counts = new WeakMap(),
	_subsections = new WeakMap();

@Comp('.InboxDropdown')
class InboxDropdown {

	$counts = this.$elem.find('.InboxDropdown__counts');

	$downList = JADE_ENABLED ? this.$elem.find('.js-manage-nav-dynamic') : this.$elem.find('ul.down-list');

	$sectionDivider = this.$elem.find('li.section-end');

	get counts() {
		return _counts.get(this) || 0;
	}

	set counts(val) {
		this.$counts.text(val);
		this.$elem.toggleClass('InboxDropdown--has-count', val > 0);

		_counts.set(this, val);
	}

	get subsections() {
		return _subsections.get(this) || [];
	}

	set subsections(val) {
		if (JADE_ENABLED) {
			this.$downList.find('.js-manage-nav-added-item').remove();
		} else {
			this.$downList.find('li.down-list__subSection').remove();
		}

		if (Array.isArray(val)) {
			val.forEach((section) => {
				if (JADE_ENABLED) {
					this.$downList.prepend(microTemplate(TMPL.row, { ...section, ICONS }));
				} else {
					this.$sectionDivider.before(microTemplate(TMPL.row, section));
				}
			});
		}

		_subsections.set(this, val);
	}

	constructor() {
		this.update();
	}

	update() {
		$.getJSON('/ajax/inbox/inbox_counts_json.php')
			.done((data) => {
				// Occurs when not authenticated (404)
				if (!data) {
					return;
				}

				if (!data.success) {
					setMessage(data.errorMessage);
					console.error(data.error);
					return;
				}

				this.counts = data.counts;
				this.subsections = data.subsections;
			});
	}
}
