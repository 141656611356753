import {
	getMaxZIndex,
} from '@utils/dom';
import { isEnabled } from 'FeatureToggle.util';

const JADE_IS_ENABLED = isEnabled('jade');

/********************************************
 JavaScript for Tooltips

 Style:
 - Tooltips.mod.css
 ********************************************/

export default (function () {

	/**
	 * Keeps a reference to each trigger element, and its corresponding tooltip instance
	 * Holds an array of objects
	 * {
	 *     element: {HTMLElement},
	 *     instance: {Tooltip}
	 * }
	 *
	 * @type {Array}
	 */
	var elementRegister = [];
	var delayTimer;
	/**
	 * Default values for building tooltips
	 *
	 * @type {{arrowHeight: number, triggerSelector: string, showDelay: number}}
	 */
	var DEFAULT = {
		arrowHeight: 5,
		triggerSelector: '.bhrTooltip',
		showDelay: 300
	};
	if (JADE_IS_ENABLED) {
		DEFAULT.arrowHeight = 7;
	}

	// *******************************************************************
	// Utilities
	// *******************************************************************

	var Utils = {};

	/**
	 * Loops through an array and performs a callback on each item in the array
	 *
	 * @param obj
	 * @param callback
	 * @returns {Array}
	 */
	Utils.each = function (obj, callback) {
		var results = [];
		for (var i = 0; i < obj.length; i++) {
			results.push(callback.call(obj, obj[i], i));
		}
		return results;
	};

	/**
	 * Loops through an array backwards and performs a callback on each item in the array
	 *
	 * @param obj
	 * @param callback
	 * @returns {Array}
	 */
	Utils.backwards = function (obj, callback) {
		var results = [];
		for (var i = obj.length - 1; i >= 0; i--) {
			results.push(callback.call(obj, obj[i], i));
		}
		return results;
	};

	/**
	 * Checks if an elements has a certain className
	 *
	 * @param element
	 * @param className
	 * @returns {boolean}
	 */
	Utils.hasClass = function (element, className) {
		return !!element.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
	};

	/**
	 * Adds a className to an element
	 *
	 * @param element
	 * @param className
	 */
	Utils.addClass = function (element, className) {
		if (!Utils.hasClass(element, className)) {
			element.className = (element.className + ' ' + className).trim();
		}
	};

	/**
	 * Removes a className from an element
	 *
	 * @param element
	 * @param className
	 */
	Utils.removeClass = function (element, className) {
		if (Utils.hasClass(element, className)) {
			var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
			element.className = element.className.replace(reg, ' ').trim();
		}
	};

	/**
	 * Detects if argument is a dom element
	 *
	 * @param  * possible element
	 *
	 * @return {Boolean}
	 */
	Utils.isDOM = function(obj) {
		return obj && obj.nodeType === 1;
	};

	// *******************************************************************
	// General use functions that do not need to be scoped to the Class
	// *******************************************************************

	/**
	 * Checks if a trigger element is already registered and has a corresponding Tooltip instance.
	 * If the trigger element is registered, the Tooltip instance will be returned.
	 * This only happens when using the .create() method using JS to create tooltips.
	 *
	 * @param queryEl
	 * @returns {boolean}
	 */
	function isRegistered(queryEl) {
		var registered = false;
		Utils.each(elementRegister, function (register) {
			if (register.element === queryEl) {
				registered = register.instance;
				return false;
			}
		});
		return registered;
	}

	/**
	 * Adds an element and its Tooltip instance to the elementRegister
	 *
	 * @param element
	 * @param instance
	 */
	function registerElement(element, instance) {
		elementRegister.push({
			element: element,
			instance: instance
		});
	}

	/**
	 * Removes an element and it's instance from elementRegister
	 * @param element
	 */
	function unRegisterElement(element) {
		elementRegister = elementRegister.filter(function (reg) {
			return reg.element !== element
		});
	}

	/**
	 * Gets the remaining tooltip data that may not have been passed when creating a tooltip
	 *
	 * @param triggerEl
	 * @param options
	 * @returns object
	 */
	function prepToolTipData(triggerEl, options) {
		var box = triggerEl.getBoundingClientRect();

		return {
			height: box.height,
			width: box.width,
			placement: (options.placement) || 'top',
			align: (options.align) || 'center',
			lead: options.lead,
			delay: (options.delay) ? options.delay : DEFAULT.showDelay,
			textAlign: (options.textAlign) || 'center',
			content: options.content,
			className: options.className || '',
			zIndex: options.zIndex || getMaxZIndex(),
			markup: options.markup
		};
	}


	/**
	 * Finds new triggers that have not been registered yet.
	 * Triggers may not be direct children of CODE or PRE tags
	 *
	 * @returns {Array}
	 */
	function findNewTriggers() {
		var allTriggers = document.querySelectorAll('[data-tip-content]');
		var newTriggers = [];

		Utils.each(allTriggers, function (trigger) {
			var tagName = trigger.parentNode.tagName.toLowerCase();

			if (!isRegistered(trigger) && tagName != 'code' && tagName != 'pre') {
				newTriggers.push(trigger);
			}

		});

		return newTriggers;
	}

	/**
	 * Gets data from data- attributes
	 * This is used when obtaining data about tooltips that are being created by HTML attributes (NON JS)
	 *
	 * @param triggerEl
	 * @returns object
	 */
	function getTriggerDataDash(triggerEl) {
		return {
			placement: triggerEl.getAttribute('data-tip-placement'),
			align: triggerEl.getAttribute('data-tip-align'),
			lead: triggerEl.getAttribute('data-tip-lead'),
			delay: triggerEl.getAttribute('data-tip-delay'),
			textAlign: triggerEl.getAttribute('data-tip-text-align'),
			content: triggerEl.getAttribute('data-tip-content'),
			className: triggerEl.getAttribute('data-tip-class'),
			zIndex: triggerEl.getAttribute('data-tip-z-index')
		}
	}

	/**
	 * Creates and returns an instance of Tooltips based on the trigger element, and all tooltip options
	 *
	 * @param triggerEl
	 * @param options
	 * @returns {Tooltip}
	 */
	function createTooltipInstance(triggerEl, options) {
		try {
			options.content = JSON.parse(options.content);
		} catch (e) {
			// Moved from legacy code as empty block. This comment exists to avoid an empty block linting error
		}

		var allOptions = prepToolTipData(triggerEl, options);

		var newToolTip = new Tooltip(triggerEl, allOptions);

		registerElement(triggerEl, newToolTip);

		return newToolTip;
	}

	// *******************************************************************
	// Private functions for Class (Tooltip) use only
	// *******************************************************************

	/**
	 * Creates the actual tooltips and appends them to the body
	 * PRIVATE USE FUNCTION
	 */
	var createTooltip = function () {
		setBody.call(this);

		Utils.addClass(this.tooltipEl, this.options.textAlign);
		Utils.addClass(this.tooltipEl, this.options.className);
		this.tooltipEl.setAttribute('placement', this.options.placement);

		document.body.appendChild(this.tooltipEl);
	};

	var setBody = function () {
		// Give priority to arrays
		if (this.options.content instanceof Array) {
			var ul = document.createElement('ul');

			Utils.each(this.options.content, function (item) {
				var li = document.createElement('li');
				li.textContent = item;

				ul.appendChild(li);
				if (JADE_IS_ENABLED) {
					ul.appendChild(document.createElement('br'));
				}
			});

			this.tooltipEl.appendChild(ul);
		} else {
			this.tooltipEl.textContent = this.options.content;
		}

		// If there is lead text
		if (this.options.lead) {
			var p = document.createElement('p');
			p.className = 'lead';
			p.textContent = this.options.lead;
			if (JADE_IS_ENABLED) {
				this.tooltipEl.insertBefore(document.createElement('br'), this.tooltipEl.firstChild);
			}
			this.tooltipEl.insertBefore(p, this.tooltipEl.firstChild);
		}

		if (this.options.markup) {
			this.tooltipEl.innerHTML = this.options.markup;
		}
	};

	/**
	 * Positions the tooltips when the mouse enters the trigger element
	 * PRIVATE USE FUNCTION
	 */
	var positionTooltip = function () {
		this.tooltipEl.style.zIndex = this.options.zIndex;

		var triggerPos = BambooHR.Utils.getRelativePosition(this.triggerEl);

		switch (this.options.placement) {
			case 'right':
				this.tooltipEl.style.top = triggerPos.top + (triggerPos.height / 2) - (this.tooltipEl.offsetHeight / 2) + 'px';
				this.tooltipEl.style.left = triggerPos.left + triggerPos.width + DEFAULT.arrowHeight + 'px';
				break;

			case 'topright':
				this.tooltipEl.style.top = triggerPos.top + 'px';
				this.tooltipEl.style.left = triggerPos.left + triggerPos.width + DEFAULT.arrowHeight + 'px';
				break;

			case 'bottom':
				this.tooltipEl.style.top = (triggerPos.top + triggerPos.height) + DEFAULT.arrowHeight + 'px';
				this.tooltipEl.style.left = (triggerPos.left + (triggerPos.width / 2)) - (this.tooltipEl.offsetWidth / 2) + 'px';
				break;

			case 'left':
				this.tooltipEl.style.top = triggerPos.top + (triggerPos.height / 2) - (this.tooltipEl.offsetHeight / 2) + 'px';
				this.tooltipEl.style.left = triggerPos.left - (this.tooltipEl.offsetWidth + DEFAULT.arrowHeight) + 'px';
				break;

			case 'topleft':
				this.tooltipEl.style.top = triggerPos.top + 'px';
				this.tooltipEl.style.left = triggerPos.left - (this.tooltipEl.offsetWidth + DEFAULT.arrowHeight) + 'px';
				break;

			case 'top':
			/* falls through */
			default:
				this.tooltipEl.style.top = triggerPos.top - this.tooltipEl.offsetHeight - DEFAULT.arrowHeight + 'px';
				this.tooltipEl.style.left = (triggerPos.left + (triggerPos.width / 2)) - (this.tooltipEl.offsetWidth / 2) + 'px';
		}

		switch (this.options.align) {
			case 'left':
				this.tooltipEl.style.left = triggerPos.left + 'px';
				break;
			case 'right':
				this.tooltipEl.style.left = 'auto';
				this.tooltipEl.style.right = triggerPos.right + 'px';
				break;
		}
	};

	/**
	* Removes events on each trigger element for showing the tooltip, and hiding based on mouse events
	* PRIVATE USE FUNCTION
	*/
	var removeEvents = function () {
		this.triggerEl.removeEventListener('mouseenter', tooltipMouseEnter);
		this.triggerEl.removeEventListener('mouseleave', tooltipMouseLeave);
	}


	/**
	 * Sets events on each trigger element for showing the tooltip, and hiding based on mouse events
	 * PRIVATE USE FUNCTION
	 */
	var setEvents = function () {
		this.triggerEl.addEventListener('mouseenter', tooltipMouseEnter.bind(this));
		this.triggerEl.addEventListener('mouseleave', tooltipMouseLeave.bind(this));
	};

	function tooltipMouseEnter(event) {
		var self = this;
		event.stopPropagation();
		delayTimer = setTimeout(function() {
			self.show();
		}, self.options.delay);
	}

	function tooltipMouseLeave(event) {
		var self = this;
		event.stopPropagation();
		self.hide();
		clearTimeout(delayTimer);
	}

	// *******************************************************************
	// Tooltip Class and chain-able methods
	// *******************************************************************

	/**
	 * Tooltip Constructor
	 *
	 * @param triggerEl
	 * @param options
	 * @constructor
	 */
	function Tooltip(triggerEl, options) {
		this.triggerEl = triggerEl;
		this.options = options;
		this.isSuppressed = false;
		this.tooltipEl = false;
		setEvents.call(this);
	}

	/**
	 * Sets the markup in the instance's element
	 * @param {string} markup Markup to render inside of the tooltip
	 */
	Tooltip.prototype.setMarkup = function (markup) {
		this.options.markup = markup;
		if (this.tooltipEl) {
			this.tooltipEl.innerHTML = markup;
		}
		return this;
	};

	/**
	 * Shows a tooltip in the UI
	 * chain-able
	 *
	 * @returns {Tooltip}
	 */
	Tooltip.prototype.show = function () {
		if (!this.isSuppressed) {
			if (!this.tooltipEl) {
				this.tooltipEl = document.createElement('div');
				this.tooltipEl.className = 'js-bhrTooltip bhrTooltip-tip';
				createTooltip.call(this);
			}
			positionTooltip.call(this);
			Utils.addClass(this.tooltipEl, 'show');
		}
		return this;
	};

	/**
	 * Hides a tooltip
	 * chain-able
	 *
	 * @returns {Tooltip}
	 */
	Tooltip.prototype.hide = function () {
		if (this.tooltipEl) {
			Utils.removeClass(this.tooltipEl, 'show');
		}
		return this;
	};

	/**
	 * Keeps a tooltip from being able to be shown
	 * chain-able
	 *
	 * @returns {Tooltip}
	 */
	Tooltip.prototype.suppress = function () {
		this.hide();
		this.isSuppressed = true;
		return this;
	};

	/**
	 * Removes the suppress effect. Allows a tooltip to show up again
	 * chain-able
	 *
	 * @returns {Tooltip}
	 */
	Tooltip.prototype.release = function () {
		this.isSuppressed = false;
		return this;
	};

	/**
	 * Destroys a tooltip. Removes the tooltip element, and removes the triggerElement from the registry
	 * Does not return `this`
	 */
	Tooltip.prototype.destroy = function () {
		removeEvents.call(this);
		if (this.tooltipEl) {
			this.tooltipEl.parentNode.removeChild(this.tooltipEl);
		}
		unRegisterElement(this.triggerEl);
	};

	// *******************************************************************
	// General Library Interaction Methods
	// *******************************************************************

	/**
	 * Create a new tooltip using JavaScript.
	 *
	 * @param selector
	 * @param options
	 * @returns {*}
	 */
	Tooltip.create = function (selector, options) {
		var triggerEl = (selector.nodeType && selector.nodeType == 1) ? selector : document.querySelector(selector);

		if (!triggerEl) {
			console.warn('Whoa! There\'s no element on the page with a selector of: ' + selector);
			return false;
		}

		if (isRegistered(triggerEl)) {
			console.warn('There is already a tooltip for element "' + selector + '". The tooltip instance has been returned to you.');

			return Tooltip.selectTooltips(selector);
		}

		Tooltip.purge();

		return createTooltipInstance(triggerEl, options);
	};

	/**
	 * Creates new tooltips based on data- attributes that exists on the page and do not need references.
	 */
	Tooltip.init = function () {
		var newTriggers = findNewTriggers();

		/* Optimizations
			1. getMaxZIndex() is expensive, do it once and then increment in the loop.
			2. Tooltip.init() gets called multiple times per page.  Only init when newTriggers.length > 0
		*/
		if (newTriggers.length > 0) {
			var maxZIndex = getMaxZIndex();
			Utils.each(newTriggers, function (triggerEl) {
				var options = getTriggerDataDash(triggerEl);
				options.zIndex = ++maxZIndex;
				createTooltipInstance(triggerEl, options);
			});
		}

		Tooltip.purge();
	};

	/**
	 * Search available tooltips using a selector for a trigger element
	 * If tooltip instances are found, they are returned in an array.
	 *
	 * @param selector
	 * @returns {Array}
	 */
	Tooltip.selectTooltips = function (selector) {
		var registered = [];
		var elements = [];

		if (Utils.isDOM(selector)) {
			elements = [selector];
		} else if (selector instanceof NodeList || Array.isArray(selector)) {
			elements = selector;
		} else if (selector !== '' && (typeof selector === 'string' || selector instanceof String)) {
			elements = document.querySelectorAll(selector);
		}

		Utils.each(elements, function (el) {
			var instance = isRegistered(el);

			if (instance) {
				registered.push(instance);
			}
		});

		return registered;
	};

	/**
	 * Loops through each tooltip that is registered. Runs a callback function on the tooltip
	 *
	 * @param fn
	 */
	Tooltip.eachTooltip = function (fn) {
		Utils.each(elementRegister, function (register) {
			fn(register.instance);
		});
	};

	/**
	 * Removes the tooltips that are no longer being used.
	 * Removes tooltip element from DOM
	 * Deletes from the elementRegister
	 */
	Tooltip.purge = function () {
		Utils.backwards(elementRegister, function (reg, i) {
			if (!document.body.contains(reg.element.parentNode)) {
				var tip = reg.instance.tooltipEl;
				if (tip) {
					tip.parentNode.removeChild(tip);
				}

				elementRegister.splice(i, 1);
			}
		});
	};

	/**
	 * Returns the available methods for general library interaction
	 */
	return {
		create: Tooltip.create,
		init: Tooltip.init,
		selectTooltips: Tooltip.selectTooltips,
		eachTooltip: Tooltip.eachTooltip,
		purge: Tooltip.purge
	}

})();
