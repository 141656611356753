import {Button, Flex} from '@bamboohr/fabric';
import {
    GH_BUTTON_TEXT,
    HEADER,
} from "../../lock-modal.mod/constants";
import React from "react";

export function GHLoginForm(props) {
    const {
        userId,
        username,
        buttonClickAction
    } = props;

    return (
        <form
            onKeyDown={ (e) => {
                if (e.key === 'Escape') {
                    e.stopPropagation();
                }
            } }
            onSubmit={ e => e.preventDefault() }
            style={{width: 'auto', paddingTop: '0'}}
            tabIndex="-1"
        >

            <h3 >{ HEADER }</h3>
                <Button size='biggie' onClick={buttonClickAction} type='button'>
                    <Flex justifyContent='center' alignItems='center' gap={1.5}>
                        {GH_BUTTON_TEXT}
                    </Flex>
                </Button>
            <input type="hidden" id="userid" name="userid" value={ userId } />
            <input type="hidden" id="username" name="username" value={ username } />

        </form>
    )
}
