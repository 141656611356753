import { StandardModal, Button, TextButton, CardContentSpacing } from '@bamboohr/fabric';
import { PeoplePlus54x54 } from '@bamboohr/grim';
import React, { FC } from 'react';

import { BenchmarkingStatusUpdateModalProps } from '../types';

export const BenchmarkingOptInModal: FC<BenchmarkingStatusUpdateModalProps> = ({isOpen, onSubmit, onRequestClose, isProcessing}) => {
    return (
        <StandardModal isOpen={isOpen} onRequestClose={onRequestClose} isProcessing={isProcessing}>
            <StandardModal.Body renderHeader={<StandardModal.Header title={$.__('Benchmarking')}/>}>
                <StandardModal.Constraint spacingOverrides={{top: CardContentSpacing.LARGE, bottom: CardContentSpacing.LARGE}}>
                    <StandardModal.HeroHeadline icon={<PeoplePlus54x54 />} text={$.__('Do you want to opt in to benchmarking?')} subtext={$.__('You will now have access to benchmarking features.')}/>
                </StandardModal.Constraint>
                <StandardModal.Footer actions={[<Button key='submit' onClick={onSubmit} processing={isProcessing}>{$.__('Yes, opt in')}</Button>, <TextButton key='cancel' onClick={onRequestClose} processing={isProcessing}>{$.__('Cancel')}</TextButton>]} />
            </StandardModal.Body>
        </StandardModal>
    );
};
