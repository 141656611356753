import { render } from 'base/wrapped-render';

import {
	IdentityVerification
} from './identity-verification.react';

const reactRoot = document.createElement('div');

document.body.appendChild(reactRoot);

export function renderIdentityVerification(props) {
	if (window.SESSION_USER) {
		render(
			<IdentityVerification {...props} />,
			reactRoot
		);
	}
}
