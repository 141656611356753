import { LOCK_ICON} from "../lock-modal.mod/constants";
import {GHLoginForm} from "./components/gh-lock-form";

export const openGHLockModal = (props) => {
    const { primaryAction, ...rest } = props;

    const modalOptions = {
        content: <GHLoginForm buttonClickAction={primaryAction} {...rest} />,
        hasCloseButton: false,
        header: false,
		hideActions: true,
        icon: LOCK_ICON,
        isHeadless: true,
        isOpen: true,
        primaryAction,
        primaryActionKey: 'Enter',
        type: 'small',
    };
    window.BambooHR.LockModal.setState(modalOptions);
};

export const closeGHLockModal = () => {
    window.BambooHR.LockModal.setState({ isOpen: false });
};
