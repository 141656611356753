const CLEAR_SELECTOR = '.js-SiteSearch__clear';
const INPUT_ID = 'quicksearchquery';
const INPUT_SELECTOR = `#${ INPUT_ID }`;

const BLUR_CLASS = 'SiteSearch__input--blurred';
const FOCUS_CLASS = 'SiteSearch__input--focused';
const SHOW_CLASS = 'SiteSearch__clear--show';

document.addEventListener('blur', hideInputOnBlur, true);
document.addEventListener('focus', showInputOnFocus, true);
document.addEventListener('keyup', checkSearchForValue);
document.addEventListener('mousedown', resetSearch);

function hideInputOnBlur({ target }) {
	if (target.id === INPUT_ID && target.value !== '') {
		handleSearchValue(target, 'hide');
	}
}

function showInputOnFocus({ target }) {
	if (target.id === INPUT_ID && target.value !== '') {
		handleSearchValue(target, 'show');
	}
}

function checkSearchForValue({ target }) {
	if (target.id === INPUT_ID) {
		const searchClear = target.parentElement.querySelector(CLEAR_SELECTOR);

		if (searchClear) {
			searchClear.classList.toggle(SHOW_CLASS, target.value);
		}
	}
}

function resetSearch({ target }) {
	const clearElem = target.closest(CLEAR_SELECTOR);

	if (clearElem) {
		clearSearch(clearElem);
	}
}

function clearSearch(elem) {
	const input = elem.parentElement.querySelector(INPUT_SELECTOR);

	input.classList.add(FOCUS_CLASS);

	if (elem.value !== '') {
		handleSearchValue(input, 'clear');
	}

	window.setTimeout(() => {
		input.focus();
		input.classList.remove(FOCUS_CLASS);
	});
}

function handleSearchValue(elem, action) {
	const breakpointWidth = 1500;
	const viewportWidth = window.innerWidth;
	const input = elem.parentElement.querySelector(INPUT_SELECTOR);
	const searchClear = elem.parentElement.querySelector(CLEAR_SELECTOR);

	if (searchClear) {
		switch (action) {
			case 'clear':
				elem.value = '';
				searchClear.classList.remove(SHOW_CLASS);
				break;
			case 'hide':
				input.classList.add(BLUR_CLASS);
				searchClear.classList.remove(SHOW_CLASS);
				break;
			case 'show':
				input.classList.remove(BLUR_CLASS);
				searchClear.classList.add(SHOW_CLASS);

				if (viewportWidth <= breakpointWidth) {
					setTimeout(() => { elem.selectionStart = elem.value.length; }, 50);
				}
				break;
		}
	}

}
