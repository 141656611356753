import { LoginForm } from '../login-form.react';
import { LoginPassword } from '../../../../login/login/LoginInputs/PasswordInput/login-password'
import {
    bem,
    HEADER,
    PASSWORD_INSTRUCTION,
} from '../../constants';
import React from "react";

export function PasswordLoginForm(props) {
    return (
        <LoginForm
            headerText={HEADER}
            instructionText={PASSWORD_INSTRUCTION}
            {...props}
        >
            <div className={`fieldWrap fab-InputWrapper ${bem.elem('passwordInput')}`}>
                <LoginPassword inputId={'p1'} showForgotPassword={false}/>
            </div>
        </LoginForm>
    );
}
