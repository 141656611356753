import {
	noop,
} from 'lodash';

import {
	isJqueryElement,
} from 'BambooHR.util';

const BREAKOUT_PARENT_CLASS = 'js-chosen-breakout-container';
const _$container = new WeakMap();
const _$select = new WeakMap();
const _$drop = new WeakMap();

function shouldBreakout() {
	return $(this).closest('.' + BREAKOUT_PARENT_CLASS).length > 0;
}

function get(obj) {
	if (isJqueryElement(obj)) {
		obj = obj[0];
	}

	return (
		_$select.get(obj) ||
		_$drop.get(obj)
	)
}

function setupSelect() {
	if (
		!this::shouldBreakout() ||
		get(this)
	) {
		return;
	}

	const chosenBreakout = new ChosenBreakout(this);
}

function call(event) {
	const chosenBreakout = get(this);

	if (!(chosenBreakout instanceof ChosenBreakout)) {
		return;
	}

	chosenBreakout::(chosenBreakout[event] || noop)();
}

export default class ChosenBreakout {
	static init() {
		$('select.chzn-done').each(setupSelect);

		$(document)
			.off('.ChosenBreakout')
			.on('liszt:ready.ChosenBreakout', 'select', setupSelect);

		window.BambooHR.subscribe('chosen:open:after', ($drop) => {
			$drop::call('onOpen');
		});
		window.BambooHR.subscribe('chosen:close:after', ($drop) => {
			$drop::call('onClose');
		});
	}

	get $select() {
		return $(_$select.get(this));
	}

	get $container() {
		return $(_$container.get(this));
	}

	get $drop() {
		return $(_$drop.get(this));
	}

	get containerPosition() {
		let {
			top,
			right,
			bottom,
			left,
			width,
			height,
		} = this.$container[0].getBoundingClientRect();

		top += window.scrollY || window.pageYOffset;
		left += window.scrollX || window.pageXOffset;

		return {
			top,
			right,
			bottom,
			left,
			width,
			height,
		};
	}

	get nosearch() {
		return this.$container.is('[class*="-nosearch"]');
	}

	constructor(select) {
		const $select = $(select);
		const $container = $select.next('.chzn-container');
		const $drop = $container.find('.chzn-drop');

		_$select.set(this, $select[0]);
		_$select.set($select[0], this);

		_$container.set(this, $container[0]);
		_$container.set($container[0], this);

		_$drop.set(this, $drop[0]);
		_$drop.set($drop[0], this);

		this.$drop
			.css('position', 'absolute');
	}

	onOpen() {
		const {
			top,
			left,
			width,
			height,
		} = this.containerPosition;

		this.$drop
			.css({
				top: top + height,
				left,
				width,
			})
			.toggleClass('chzn-drop-nosearch', this.nosearch)
			.appendTo(document.body);
	}

	onClose() {
		this.$drop.appendTo(this.$container);
	}
}
