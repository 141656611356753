export const ENDPOINT = '/auth';

// Login types
export const GOOGLE_TYPE = 'google';
export const OKTA_TYPE = 'okta';
export const ONE_LOGIN_TYPE = 'one_login';
export const PASSWORD_TYPE = 'password';
export const SAML_TYPE = 'saml';
export const MICROSOFT_TYPE = 'microsoft';
export const QUICKBOOKS_SSO_TYPE = 'qbop';
