// Attach to window to share with Micro-FEs. They need to use the same ThemeProvider for now.
// Revisit this after MUI v5
import * as Fabric from '@bamboohr/fabric';
import moment from 'moment';
window.Fabric = Fabric;
// Recoil is used to sync state between Main and Po
import * as Recoil from 'recoil';
window.Recoil = Recoil;

import { PoMicroFrontend } from 'micro-frontend.react';
import { render } from 'base/wrapped-render';
import { ifFeature, isEnabled } from '@bamboohr/utils/lib/feature';

const jadeIsEnabled = isEnabled('jade');

// @startCleanup encore
const encoreEnabled = ifFeature('encore', true, false);
// @endCleanup encore

import './styles';

window.BambooHR = window.BambooHR || {};

import './forms';

import 'MultilineEllipsis.mod';

import 'velocity-animate';

import 'Inputmask.mod';

import 'CalendarPicker.mod';

import SimpleModal from 'SimpleModal.mod';
if (!jadeIsEnabled) {
	window.SimpleModal = SimpleModal;
}

import { initGlobalModal, initLockModal } from 'global-modal';

if (jadeIsEnabled) {
	initGlobalModal();
	initLockModal();
}

window.dateFnsDefaultFormat = initializeDefaultDateFnsFormat();

function initializeDefaultDateFnsFormat() {
	let convertedFormat = window.GLOBAL_DATE_MASK || 'MM/dd/yyyy';
	convertedFormat = convertedFormat.replaceAll('m', 'M').replaceAll('Mon', 'MMM');
	convertedFormat = convertedFormat.replaceAll('D', 'd');
	convertedFormat = convertedFormat.replaceAll('Y', 'y');
	return convertedFormat;
}

/**
 * load Esigs.
 * See - app/templates/twig/inbox/pages/signatures/signature.html.twig:32
 *
 * @param {function}	cb      The Esigs Callback
 * @param {element} 	elem	The triggering element.
 * @param {function}	preImport 	A callBack to run prior to the import.
 *
 */
window.lazySigs = function (cb, elem = null, preImport) {
	if (typeof preImport === 'function') {
		preImport(elem);
	}
	import(/* webpackChunkName: "esignatures-lazy" */ 'Esignatures.mod').then((Module) => {
		window.Esignatures = Module.default;
		cb(window.Esignatures, elem);
	});
};

import BambooScrollLock from 'BambooScroll.mod';
window.BambooScrollLock = BambooScrollLock;

import VirtualPageView from 'VirtualPageLogger.service';
window.BambooHR.VirtualPageView = VirtualPageView;

import HistoryTable from 'HistoryTable.mod';
window.BambooHR.HistoryTable = HistoryTable;

import StickyNav from 'StickyNav.mod';

import Balloon from 'Balloon.mod/Balloon.mod';
window.BambooHR.Balloon = Balloon;

import Popover from 'Popover.mod';
window.BambooHR.Popover = Popover;

import Tooltip from 'Tooltip.mod';
window.BambooHR.Tooltip = Tooltip;

import 'legacy-modules/photo-crop.mod';

import * as globalFileManagementFunctions from 'legacy-modules/file-management.mod';
Object.assign(window, globalFileManagementFunctions);

import 'tablesorter.lib';

import 'InboxDropdown.mod';

import 'LegacyComponents.mod';

import 'jquery.autocomplete.lib';

import 'legacy-modules/InsertableList.mod';

import 'jquery.inlineEdit.lib';

import 'jquery.autoInputList.lib';

import 'jquery.tmpl.lib';

import 'manage-session.mod';

import 'mobile-qr-login.mod';

import 'chosen.jquery.lib';

import 'livesearch.lib';

import 'HelpCenter.mod';

import './search';

/* @startCleanup encore */
import 'setup-summary-banner.mod';
/* @endCleanup encore */
import './answers';

import * as baseFunctions from './base';
Object.assign(window, baseFunctions);

import * as ModalsMod from 'legacy-modules/Modals.mod';
const { Modal, ...modalFunctions } = ModalsMod;
Object.assign(window, modalFunctions);

if (!jadeIsEnabled) {
	Object.assign(window, { Modal });
}

Modal.init();

import { showCookieModal } from './cookies';
window.checkForCookieAcceptance = showCookieModal;

import { checkAvailableRepeatedly, toggleChatAvailability, loadSnapEngage, wireSnapEngage, wireSalesforceChat } from './chat';
import {observeScroll} from "@bamboohr/utils/lib/observe-scroll";
import { handleScroll } from '@bamboohr/utils/lib/handle-scroll/handle-scroll';
window.checkAvailableRepeatedly = checkAvailableRepeatedly;
window.toggleChatAvailability = toggleChatAvailability;
window.loadSnapEngage = loadSnapEngage;
window.wireSnapEngage = wireSnapEngage;
window.wireSalesforceChat = wireSalesforceChat;

function renderGlobalPoElements() {
	// @startCleanup encore
	if (!encoreEnabled) {
		return;
	}
	// @endCleanup encore

	let poData;

	try {
		poData = JSON.parse(document.getElementById('poData')?.innerText || '{}');
	} catch (e) {
		console.error('Error getting header and navigation data');
	}
	
	if(poData){
		const banners = document.getElementById('js-BannerContainer');
		const header = document.getElementById('js-GlobalSiteHeader');
		const navigation = document.getElementById('js-GlobalNavigation');

		if (banners) {
			render(<PoMicroFrontend {...poData} route='/site/po-banners' />, banners);
		}
	
		if (header) {
			render(<PoMicroFrontend {...poData} route='/site/po-site-header' />, header);
		}
		if (navigation) {
			render(<PoMicroFrontend {...poData} route='/site/po-global-navigation' />, navigation);
		}
	}
}

renderGlobalPoElements();

// TODO: Replace with function from Scroll Manager in FE-Utils
function getScrollContainer() {
	return ifFeature('encore', document.getElementById('js-GlobalScrollContainer') || window);
}

// TODO: Replace with function from Scroll Manager in FE-Utils
function isScrollable() {
	const scrollContainer = getScrollContainer();
	let { clientHeight, clientWidth, scrollHeight, scrollWidth } = scrollContainer;
	if (scrollContainer === window) {
		clientHeight = window.innerHeight;
		clientWidth = window.innerWidth;
		scrollHeight = document.documentElement.scrollHeight;
		scrollWidth = document.documentElement.scrollWidth;
	}
	return scrollHeight > clientHeight || scrollWidth > clientWidth;
}

function wireScrollDivider() {
	const globalSiteHeader = document.getElementById('js-GlobalSiteHeader');
	const pageCapsule = document.getElementById('js-PageCapsule');
	const scrollContainer = getScrollContainer();
	const scrollDivider = document.getElementById('js-ScrollDivider');
	if (!globalSiteHeader || !scrollContainer || !scrollDivider) {
		return;
	}

	handleScroll(
		globalSiteHeader,
		pageCapsule,
		'top',
		(isAtTop) => {
			scrollDivider.classList.toggle('ScrollDivider--visible', !isAtTop);
		},
		-15,
	);
}

function wireActionFooter() {
	function handleFooterScroll(footerId) {
		const footer = document.querySelector(footerId);
		const containerElement = document.querySelector('.PageCapsule');

		if (!footer || !containerElement) {
			return;
		}

		const elementWatch = document.getElementById('encore-hidden-footer-js');
		let disconnectObserver;
		
		disconnectObserver = observeScroll(elementWatch, '0px 0px -1px 0px', isIntersecting => {
			footer.classList.toggle('PageCapsule__footer--borderTop', !isIntersecting);
		});
		
		if (!isScrollable()) {
			footer.classList.remove('PageCapsule__footer--borderTop');
		}

		// If the footer is removed from the DOM, disconnect the observer
		const mutationObserver = new MutationObserver(() => {
			if (!document.contains(footer)) {
				disconnectObserver();
				mutationObserver.disconnect();
			}
		});

		mutationObserver.observe(document, { childList: true, subtree: true });
	}

	// Footer found in site_footer.html.twig, legacy footer
	const siteFooter = '#encore-action-footer-js';
	// Footer found in page_actions_footer.js, React footer
	const pageActionFooter = '#encore-page-actions-js';

	if (document.querySelector(siteFooter)) {
		handleFooterScroll(siteFooter);
	}

	// pageActionFooter is conditionally rendered, so we need to observe the DOM for changes
	const observer = new MutationObserver((mutationsList, observer) => {
		// If the `encore-page-actions-js` element is added to the DOM, call `handleFooterScroll`
		if (document.querySelector(pageActionFooter)) {
			handleFooterScroll(pageActionFooter);
			// Once the `encore-page-actions-js` element is found, we can stop observing for changes in the DOM
			observer.disconnect();
		}
	});

	// Start observing the document with the configured parameters
	observer.observe(document, { childList: true, subtree: true });
}

function markLegacyIcons() {
	// change deprecated icons to construction tape so it's obvious they should not be used
	const styleElement = document.createElement('style');
	styleElement.innerHTML = `
		ba-icon:not([encore-name]) svg, svg[data-bhr-grim] {
			background: repeating-linear-gradient(45deg, #222222, #222222 4px, #f0d232 4px, #f0d232 8px);
			& * {
				display: none;
			}
		}
	`;
	document.head.appendChild(styleElement);
}	
	
document.addEventListener(
	'DOMContentLoaded',
	ifFeature(
		'encore',
		() => {
			wireActionFooter();
			wireScrollDivider();
			if (window.ENV !== 'prod') {
				markLegacyIcons();
			}
		},
		() => {}
	)
);
