/* @startCleanup encore */
import React, { useEffect } from 'react';
import {
	Banner as FabricBanner,
	BannerProps as FabricBannerProps,
	Button,
	ButtonProps,
	Flex,
	IconButton,
	makeStyles,
} from '@bamboohr/fabric';
import { X14x14 } from '@bamboohr/grim';

export interface BannerProps {
	/**
	 * The icon to display on the left of the title.
	 */
	icon?: FabricBannerProps['icon'];
	/**
	 * The title of the banner.
	 */
	title: string;
	/**
	 * The subtitle of the banner, displayed directly below the title
	 */
	subtitle?: string;
	/**
	 * Text to render to the right of the title.
	 */
	note?: string;
	onClose?: FabricBannerProps['onClose'];
	/**
	 * Data attribute used for business intelligence applied to the primary button.
	 */
	primaryButtonBiId?: string | null;
	/**
	 * Props for the right button.
	 */
	PrimaryButtonProps?: ButtonProps;
	/**
	 * Data attribute used for business intelligence applied to the secondary button.
	 */
	secondaryButtonBiId?: string | null;
	/**
	 * Props for the left button.
	 */
	SecondaryButtonProps?: ButtonProps;
}

const useStyles = makeStyles(({ mixins, palette, spacing, typography }) => ({
	root: {
		backgroundColor: palette.grey[1000],
		boxSizing: 'border-box',
		color: palette.common.white,
		minHeight: 114,
		overflow: 'hidden',
		padding: spacing(3.75, 3.375, 3.75, 4), // 30px 27px (32px - 5px = 27px) 30px 32x
	},
	content: {
		alignItems: 'center',
		display: 'flex',
	},
	icon: {
		marginRight: spacing(2),
	},
	titleSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
	},
	copyWrapper: {
		alignItems: 'flex-end',
		display: 'inline-flex',
	},
	title: {
		...typography.h2,
		margin: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	subtitle: {
		color: palette.grey[300],
		fontSize: typography.fabricFontSize('medium'),
	},
	note: {
		color: palette.grey[300],
		fontSize: typography.fabricFontSize('large'),
		lineHeight: typography.fabricLineHeight('large'),
		marginLeft: spacing(1),
		whiteSpace: 'nowrap',
	},
	buttonGroup: {
		display: 'inline-flex',
		paddingRight: spacing(3),
		borderRight: `1px solid ${mixins.alpha(palette.common.white, 0.5)}`,
	},
	closeButtonWrapper: {
		fill: palette.common.white,
		paddingLeft: `19px`,
	},
	closeButton: {
		padding: 5,
	},
	contained: {
		'&$altStyling': {
			color: palette.grey[1000],
		},
	},
	altStyling: {},
}));

export function Banner({
	icon,
	title,
	subtitle,
	note,
	onClose,
	primaryButtonBiId,
	PrimaryButtonProps,
	secondaryButtonBiId,
	SecondaryButtonProps,
}: BannerProps): JSX.Element {
	const styles = useStyles();

	useEffect(() => {
		// Update nav position
		document.dispatchEvent(new Event('updateNavPosition'));
	}, []);

	const sharedButtonProps = {
		classes: {
			altStyling: styles.altStyling,
			contained: styles.contained,
		},
		dark: true,
		size: 'biggie' as ButtonProps['size'],
	};

	return (
		<FabricBanner classes={{ root: styles.root, content: styles.content, icon: styles.icon }} icon={icon}>
			<Flex alignItems='center' justifyContent='space-between' width='100%'>
				<div className={styles.titleSection}>
					<div className={styles.copyWrapper}>
						<span className={styles.title}>{title}</span>
						{note && <span className={styles.note}>{note}</span>}
					</div>
					<div className={styles.subtitle}>{subtitle}</div>
				</div>
				<div className={styles.buttonGroup}>
					{PrimaryButtonProps && (
						<Button data-bi-id={primaryButtonBiId} variant='outlined' {...sharedButtonProps} {...PrimaryButtonProps} />
					)}
					{SecondaryButtonProps && <Button data-bi-id={secondaryButtonBiId} {...sharedButtonProps} {...SecondaryButtonProps} />}
				</div>
			</Flex>
			<div className={styles.closeButtonWrapper}>
				<IconButton
					className={styles.closeButton}
					dark={true}
					floatingIcon={true}
					icon={<X14x14 />}
					noBoundingBox={true}
					onClick={onClose}
					type='button'
				/>
			</div>
		</FabricBanner>
	);
}
/* @endCleanup encore */
