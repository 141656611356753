import { extend } from 'lodash';

/********************************************
 JavaScript for Overlay

 Style:
 - Overlay.mod.css
 ********************************************/
export default (function() {
	let DEFAULT = {
		text: $.__('Saving'),
		fadeInTime: 500
	};

	let Overlay = {};

	Overlay.open = function(options) {
		if (Overlay._isOpen()) {
			return;
		}
		var defaultOptions = extend(DEFAULT, options);
		$('body').append(Overlay._defaultHtml);
		$('.js-Overlay__text').html(defaultOptions.text);
		$('.js-Overlay').fadeIn(defaultOptions.fadeInTime);
	};

	Overlay._isOpen = function() {
		return $('.js-Overlay').length > 0;
	};

	Overlay.close = function() {
		if (Overlay._isOpen()) {
			$('.js-Overlay').remove();
		}
	};

	Overlay._defaultHtml =
		"<div class='js-Overlay Overlay' style='display: none;'>" +
			"<div class='dotLoader Overlay__content'>" +
				"<div class='bounce1 white'></div>" +
				"<div class='bounce2 white'></div>" +
				"<div class='bounce3 white'></div>" +
			"</div>" +
			"<div class='js-Overlay__text Overlay__text'></div>" +
		"</div>";
	return {
		open: Overlay.open,
		close: Overlay.close
	};
})();
