import Popover from './Popover';
import SearchInput from './SearchInput';
import Tooltip from './Tooltip';
import ChosenBreakout from './ChosenBreakout';
import ChosenBreakoutModal from './ChosenBreakoutModal';
import Overlay from './Overlay';

/********************************************
	Javascript Component object
	- Initializes all javascript component modules in app

********************************************/
window.BambooHR.Components = {
	Popover,
	SearchInput,
	Tooltip,
	ChosenBreakout,
	ChosenBreakoutModal,
	Overlay,

	init: function () {
		this.Popover.init();
		this.SearchInput.init();
		this.Tooltip.init();
		ChosenBreakout.init();
		ChosenBreakoutModal.init();
	}

};

/* Initialize Tooltips */
$(window).load(function () {
	BambooHR.Components.init();
});

$(document).on('pjax:end simplemodal:open:after', function () {
	BambooHR.Components.init();
});

$(document).ajaxSuccess(function () {
	BambooHR.Components.init();
});
