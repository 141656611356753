import React  from 'react';

import { useBenchmarkingModal, useBenchmarkingStatus } from './hooks';

export const BenchmarkingStatusUpdate = () => {
    const { onSubmit, isProcessing, isOpen, onRequestClose, launch } = useBenchmarkingModal();
    const { BenchmarkingStatusUpdateModal } = useBenchmarkingStatus(launch);

    return BenchmarkingStatusUpdateModal ? <BenchmarkingStatusUpdateModal isOpen={isOpen} onSubmit={onSubmit} isProcessing={isProcessing} onRequestClose={onRequestClose} /> : null;
}
