/* @startCleanup encore */
import React from 'react';
import { MoneyCircle40x40 } from '@bamboohr/grim';
import { Banner } from '../components/banner';
import { SetupSummaryBannerProps } from '../types';

export function PayrollBanner({ biId, onClose, progress, progressNote, primaryUrl, title, subtitle }: SetupSummaryBannerProps) {
	let note = '';
	if (progress && progressNote) {
		note = `${progress.current}/${progress.total} ${progressNote}`;
	}

	return (
		<Banner
			SecondaryButtonProps={{
				children: $.__('Back To Payroll Registration'),
				href: '/app/payroll/registration',
			}}
			icon={<MoneyCircle40x40 />}
			note={note}
			onClose={onClose}
			secondaryButtonBiId={biId ? `${biId}-payrollBanner-backToPayroll` : null}
			subtitle={subtitle}
			title={title}
		/>
	);
}
/* @endCleanup encore */
