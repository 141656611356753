jQuery.fn.insertableList = function(postback, txt) {
	return this.each(function() {
		if ($(this).hasClass('js-insertableList-complete')) {
			return;
		}
		$(this).addClass('js-insertableList-complete');
		if (txt == undefined) {
			txt = $.__('--- add a new category ---');

			if ($(this).hasClass('th-grab')) {
				var tmp = $(this);
				while (tmp.length > 0 && (!tmp.is('tr') && !tmp.is('div.fieldBox'))) { tmp = tmp.parent(); }
				if (tmp.length > 0) {
					txt = tmp.find('th').length > 0 ? tmp.find('th').text() : tmp.find('label').text();
					txt = $.__('--- add a new option ---');
				}
			}
		}

		var optionParent = this;
		if (this.options) {
			if (this.hasAttribute('wrap-insertable-option')) {
				optionParent = document.createElement('optgroup');
				this.appendChild(optionParent);
			}
			optionParent.appendChild(new Option(txt,'new'));
			$(this).trigger('liszt:updated');
		}

		$(this).data('oldIndex', this.selectedIndex);
		var url = postback;
		if ($(this).data('fieldid')) {
			url += '?fieldId=' + $(this).data('fieldid');
		}
		$(this).data('post', url);
		$(this).change(showInsertForm);
	});
};

function insertCheckForEnter(e) {
	if (e.keyCode == 13) {
		e.preventDefault();
		saveNewSection();
		return false;
	}
}

function showInsertForm() {
	if ($(this).val() == 'new') {
		$(this).hide();
		$(this).next('.chzn-container').hide();
		$('.hideable-buttons').hide();
		hideInsertForm();
		$(this).after(
			'<div id="newSectionForm" style="display:none">' +
			'        <span class="btnGroup">' +
			'		 <input type="text" name="newName" value="" class="text ac_input js-validate-inlineEdit" maxlength="100" />' +
			'        <button type="button" class="button btn btnAction btnInlineSave">' + $.__('Save') + '</button>' +
			'		 </span>' +
			'        <span class="orCancel">'/* + $.__('or') */ +
			'        <a href="javascript:void(0)">' + $.__('Cancel') + '</a></span>' +
			'		<div style="display:none; clear:both; text-align:left" class="customMessageCount"></div>' +
			'</div>'
		);
		//var tmp=$('#newSectionForm');
		$('#newSectionForm input[type="text"]').limitCount(255, {displaySelector: $('#newSectionForm div')});

		$('#newSectionForm button')
			.on('click', saveNewSection)
			.on('mousedown', function() {
				$(this).closest('#newSectionForm')
					.attr('saving-inline', 'true');
			});

		$('#newSectionForm a').click(function() {
			$('#newSectionForm').prev('select').valid(); //unhighlight errors
			hideInsertForm();
		});
		$('#newSectionForm')
			.fadeIn('slow', function() {
				$('#newSectionForm input[type="text"]').focus();
			})
			.data('for', $(this));
		
		$('#newSectionForm input[type="text"]').keydown(insertCheckForEnter);

	} else {
		$(this).data('oldIndex', this.selectedIndex);
	}
}

function hideInsertForm() {
//	console.log('hide insert form called');
	var tmp = $('#newSectionForm').data('for');
	if (tmp) {
		$('.hideable-buttons').fadeIn('slow');
		tmp.each(function() {
			//	  	$(this).fadeIn('slow');
			this.selectedIndex = $(this).data('oldIndex');
			$(this).change();
			$(this).trigger('liszt:updated');
			$(this).next().fadeIn('slow');
		});
		$('#newSectionForm').removeData('for').remove();
		tmp.next('.chzn-container').fadeIn('slow');
	}
}

function saveNewSection() {
	var d = $('#newSectionForm');

	var field = $(d).data('for');
	var fieldName = (field.attr('data-field-id')) ? field.attr('data-field-id') : field.attr('name');

	var $name = $('input[name="newName"]', d);

	var name = $name.val();
	if (!$name.valid()) {
		if (field.data('message-error')) {
			setMessage(field.data('message-error'),'error');
		}		else {
			setMessage($.__('You must enter a new option to the list.'),'error');
		}

		d.attr('saving-inline', 'false');
		return;
	}

	var dataToPost = {
		ajax: 1,
		action: 'new-section',
		name: name,
		field: fieldName
	};

	$.post(field.data('post'), dataToPost, null, 'json')
		.done(function(data) {
			if (data.ret == 'SUCCESS') {
				var l = field.get(0);

				//Add the newly created field above the "add a new" option
				var option = new Option(l.options[l.options.length - 1].text, l.options[l.options.length - 1].value);
				l.add(option, null);
				l.options[l.options.length - 2].text = name;
				l.options[l.options.length - 2].value = data.id;

				//Set the newly created field as selected
				$(field).data('oldIndex', l.options.length - 2);

				// if this their are multiple of the same field then update all the others (contacts relationship type id)
				if (field.hasClass('js-insertableList-linked')) {
					$('select.js-insertableList-linked').each(function() {
						if (this != l) {
							var option = new Option(this.options[this.options.length - 1].text, this.options[this.options.length - 1].value);
							this.add(option, null);
							this.options[this.options.length - 2].text = name;
							this.options[this.options.length - 2].value = data.id;
							$(this).trigger('liszt:updated');
						}
					});
				}
			}
			setMessage(data.message, data.ret.toLowerCase());

			if (data.ret != 'ERROR') {
				hideInsertForm();
				field.valid(); //unhighlight errors
				if (field.attr('multiple') && field.attr('multiple') == 'multiple') {
					field.show();
				}
			}
		})
		.fail(function() {
			setMessage($.__('Error processing your request.'), 'error');
			hideInsertForm();
		})
		.always(function() {
			d.attr('saving-inline', 'false');
		});
}
