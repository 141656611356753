/* @startCleanup encore */
import React from 'react';
import { RacecarFlag48x53 } from '@bamboohr/grim';
import { Banner } from '../components/banner';
import { SetupSummaryBannerProps } from '../types';

export function QuickstartBanner({ biId, onClose, progress, progressNote, primaryUrl, title }: SetupSummaryBannerProps) {
	let note;
	if (progress && progressNote) {
		note = `${progress.current}/${progress.total} ${progressNote}`;
	}

	return (
		<Banner
			PrimaryButtonProps={{
				children: $.__('View Learning Course'),
				href: primaryUrl,
				target: '_blank',
			}}
			SecondaryButtonProps={{
				children: $.__('Back To QuickStart'),
				href: '/app/setup/quickstart',
			}}
			icon={<RacecarFlag48x53 />}
			note={note}
			onClose={onClose}
			primaryButtonBiId={biId ? `${biId}-quickstartSummaryBanner-viewLearningCourse` : null}
			secondaryButtonBiId={biId ? `${biId}-quickstartSummaryBanner-backToQuickstart` : null}
			title={title}
		/>
	);
}
/* @endCleanup encore */
