import Ajax from "@bamboohr/utils/lib/ajax";
import {isOnboardingUser, scheduleNextLockCheck} from ".././manage-session.mod";
// eslint-disable-next-line no-restricted-imports
import {setToken} from "../../_utils/csrf.util";
import {openGHLockModal} from "../gh-lock-modal.mod";
// eslint-disable-next-line no-restricted-imports
import {initGlobalModal, initLockModal} from "../../_components/global-modal";
/**
 * TODO Remove all this when we convert everything to FE GH
 * This is just a band-aid for the time being
 */

/**
 * Handles check to see if GH session time has expired
 */
function shouldCheck ({  timeoutData }) {
    const timeHasExpired =
        !timeoutData || new Date(timeoutData.sessionChecked + timeoutData.remaining) <= new Date();
    return timeHasExpired;
}

/**
 * Refreshes the session & page
 */
function handleSessionLogout() {
    window.location.reload();
}

/**
 * Opens the GH Lock Modal
 */
function openModal() {
    if (isLockModalVisible()) {
        return;
    }
    openGHLockModal({
        urlPrefix: '/greenhouse/',
        primaryAction: handleSessionLogout,
    });
}
/**
 * Checks the session timeout
 */
function checkTimeout(data){
    const timeoutData = window.localStorage.getItem('bhr_lock_times');
    const parsedTimeoutData = timeoutData && JSON.parse(timeoutData);
    if(!data.active && shouldCheck({ timeoutData: parsedTimeoutData })) {
        openModal();
    }
}

/**
 * Handles response from a server request for valid Greenhouse session status.
 */
function handleCheckGreenhouseSessionSuccess(response) {
    if(response.data && response.data.CSRFToken) {
        setToken(response.data.CSRFToken, isOnboardingUser());
        scheduleNextLockCheck(response.data.expires);
    } else {
        checkTimeout(response.data)
    }
}

/**
 * Handles the GH Session
 */
export function handleGhSession(){
    initGlobalModal();
    initLockModal();
    Ajax.get('/greenhouse/api/auth/session_status').then((handleCheckGreenhouseSessionSuccess))
}

/**
 * Checks to see if lock modal is currently visible
 */
function isLockModalVisible() {
    try {
        return window.BambooHR.LockModal.isOpen();
    } catch (error) {
        // Return false if LockModal object is undefined, or if the call fails
        return false;
    }
}
