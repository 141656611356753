import React from "react";
import { GoogleLogin20x20, OktaLogo20x20, Onelogin20x20, MicrosoftLogo20x20 } from '@bamboohr/grim';

import { GOOGLE_TYPE, OKTA_TYPE, ONE_LOGIN_TYPE, MICROSOFT_TYPE, QUICKBOOKS_SSO_TYPE } from 'manage-session.mod/constants';

import { LoginForm } from '../login-form.react';
import {
	bem,
	DEFAULT_BUTTON_TEXT,
	GOOGLE_BUTTON_TEXT,
	HEADER,
	OKTA_BUTTON_TEXT,
	ONELOGIN_BUTTON_TEXT,
	SAML_INSTRUCTION,
	MICROSOFT_BUTTON_TEXT,
	QUICKBOOKS_SSO_BUTTON_TEXT,
} from '../../constants';
import { Button, Flex, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function SamlLoginForm(props) {
	const { formType, ssoButtonClickAction } = props;

	let ButtonIcon = null;
	let buttonText = '';

	switch (formType) {
		case GOOGLE_TYPE:
			ButtonIcon = ifFeature('encore', <IconV2 name='google' size={20}/>, <GoogleLogin20x20 />);
			buttonText = GOOGLE_BUTTON_TEXT;
			break;
		case OKTA_TYPE:
			// @startCleanup encore
			ButtonIcon = ifFeature('encore', undefined, <OktaLogo20x20 />);
			// @endCleanup encore
			buttonText = OKTA_BUTTON_TEXT;
			break;
		case ONE_LOGIN_TYPE:
			// @startCleanup encore
			ButtonIcon = ifFeature('encore', undefined, <Onelogin20x20 />);
			// @endCleanup encore
			buttonText = ONELOGIN_BUTTON_TEXT;
			break;
		case MICROSOFT_TYPE:
			ButtonIcon = ifFeature('encore', <IconV2 name='microsoft' size={20}/>, <MicrosoftLogo20x20 />);
			buttonText = MICROSOFT_BUTTON_TEXT;
			break;
		case QUICKBOOKS_SSO_TYPE:
			ButtonIcon = <IconV2 name='right-to-bracket-regular' size={20} />;
			buttonText = QUICKBOOKS_SSO_BUTTON_TEXT;
			break;
		default:
			buttonText = DEFAULT_BUTTON_TEXT;
			break;
	}

	return (
		<LoginForm headerText={HEADER} instructionText={SAML_INSTRUCTION} {...props}>
			<Button className={`js-ssoButton ${bem.elem('ssoButton')}`} onClick={ssoButtonClickAction} size='biggie' type='button'>
				<Flex alignItems='center' gap={1.5} justifyContent='center'>
					{ButtonIcon ? ButtonIcon : null}
					{buttonText}
				</Flex>
			</Button>
		</LoginForm>
	);
}
