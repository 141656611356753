/* @startCleanup encore */
import React from 'react';
import pathToRegexp from 'path-to-regexp';
import { canUseLocalStorage } from '@utils/localstorage';
import Ajax from '@utils/ajax';
import { render } from 'base/wrapped-render';
import { SetupSummaryBanner } from './setup-summary-banner';
import { BannerData, SetupType } from './types';
import { LOCAL_STORAGE_KEY_NAME } from './constants';
import { removeBannerLocalStorage } from './utils';

const PATH_EXCLUSION_LIST = ['/app/setup*', '/reports*'];

const PATH_INCLUSION_LIST = ['/reports/payroll-deductions*', '/reports/pay-info-tab-direct-deposit*'];

const getStepUrl = (bannerData: BannerData) => {
	if (bannerData.type === SetupType.QUICKSTART) {
		return `/setup/${SetupType.QUICKSTART}/steps`;
	}

	if (bannerData.type === SetupType.CORE_IMPLEMENTATION) {
		return `/setup/${SetupType.CORE_IMPLEMENTATION}/steps`;
	}

	if (bannerData.type === SetupType.PAYROLL) {
		const { recordId, clientId } = bannerData;

		return recordId && clientId ? `/registration/payroll/steps?recordId=${recordId}&clientId=${clientId}` : '';
	}
};

const shouldRenderBanner = (data: any, bannerData: BannerData) => {
	if (!(data && (data.status?.markedComplete || data.steps))) {
		throw new Error('Step data status not found');
	}

	if (bannerData.type === SetupType.PAYROLL) {
		//returns whether selected step is complete
		return !data.steps.includes((step) => step.key === bannerData.stepKey && step.complete);
	}

	return !data.status.markedComplete;
};

const initSummaryBanner = () => {
	const root = document.getElementById('js-setup-summary-banner');
	const exclusionMatch = pathToRegexp(PATH_EXCLUSION_LIST).exec(window.location.pathname);
	const inclusionMatch = pathToRegexp(PATH_INCLUSION_LIST).exec(window.location.pathname)?.length;
	const initBanner = inclusionMatch || !exclusionMatch;

	if (root && canUseLocalStorage() && initBanner) {
		const bannerStorageItem = window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME);
		if (bannerStorageItem) {
			const bannerData: BannerData = JSON.parse(bannerStorageItem);
			const stepUrl = getStepUrl(bannerData);

			Ajax.get(stepUrl)
				.then(({ data }) => {
					if (shouldRenderBanner(data, bannerData)) {
						render(<SetupSummaryBanner {...bannerData} />, root);
					} else {
						return false;
					}
				})
				.catch(() => {
					removeBannerLocalStorage();
				});
		}
	}
};

initSummaryBanner();
/* @endCleanup encore */
