import { render } from 'base/wrapped-render';

import { MobileQrLoginModal } from './mobile-qr-login-modal.react';

export const mobileQrLoginMount = (): void => {
	let reactRootElement = document.getElementById('js-QrLoginModalReactRoot');

	if (!reactRootElement) {
		const qrLoginReactRoot = document.createElement('div');

		qrLoginReactRoot.id = 'js-QrLoginModalReactRoot';
		reactRootElement = qrLoginReactRoot;
		document.body.appendChild(qrLoginReactRoot);
	}

	render(<MobileQrLoginModal isModalOpen={true} key={Date.now()} />, reactRootElement);
};
