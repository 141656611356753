import {
	getMaxZIndex,
} from '@utils/dom';

/* @startCleanup encore */
// https://bamboohr.atlassian.net/browse/EN-2278
var ENCORE_ENABLED = window.BambooHR.FeatureToggle.encoreEnabled();
/* @endCleanup encore */

/********************************************
 JavaScript for Popovers

 Style:
 - Popovers.mod.css
 ********************************************/

export default (function () {

	/**
	 * Keeps a reference to each trigger element, and its corresponding Popover instance
	 * Holds an array of objects
	 * {
	 *     element: {HTMLElement},
	 *     instance: {Popover}
	 * }
	 *
	 * @type {Array}
	 */
	var elementRegister = [];

	/**
	 * Some default values for building popovers
	 *
	 * @type {{arrowHeight: number, hideClass: string}}
	 */
	var DEFAULT = {
		arrowHeight: 16,
		hideClass: 'popover-hide'
	};

	// *******************************************************************
	// Utilities
	// *******************************************************************

	var Utils = {};

	/**
	 * Loops through an array and performs a callback on each item in the array
	 *
	 * @param obj
	 * @param callback
	 * @returns {Array}
	 */
	Utils.each = function (obj, callback) {
		var results = [];
		for (var i = 0; i < obj.length; i++) {
			results.push(callback.call(obj, obj[i], i));
		}
		return results;
	};

	/**
	 * Checks if an elements has a certain className
	 *
	 * @param element
	 * @param className
	 * @returns {boolean}
	 */
	Utils.hasClass = function (element, className) {
		return !!element.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
	};

	/**
	 * Adds a className to an element
	 *
	 * @param element
	 * @param className
	 */
	Utils.addClass = function (element, className) {
		if (!Utils.hasClass(element, className)) {
			element.className = (element.className + ' ' + className).trim();
		}
	};

	/**
	 * Removes a className from an element
	 *
	 * @param element
	 * @param className
	 */
	Utils.removeClass = function (element, className) {
		if (Utils.hasClass(element, className)) {
			var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
			element.className = element.className.replace(reg, ' ').trim();
		}
	};

	// *******************************************************************
	// General use functions that do not need to be scoped to the Class
	// *******************************************************************

	/**
	 * Checks if a trigger element is already registered and has a corresponding Popover instance.
	 * If the trigger element is registered, the Popover instance will be returned.
	 * This only happens when using the .create() method using JS to create popovers.
	 *
	 * @param queryEl
	 * @returns {boolean}
	 */
	function isRegistered(queryEl) {
		var registered = false;
		Utils.each(elementRegister, function (register) {
			if (register.element === queryEl) {
				registered = register.instance;
				return false;
			}
		});
		return registered;
	}

	/**
	 * Adds an element and its Popover instance to the elementRegister
	 *
	 * @param element
	 * @param instance
	 */
	function registerElement(element, instance) {
		elementRegister.push({
			element: element,
			instance: instance
		});
	}

	/**
	 * Removes an element from the elementRegister along with it's associated Popover instance
	 * @param element
	 */
	function unRegisterElement(element) {
		elementRegister = elementRegister.filter(function (reg) {
			return reg.element !== element;
		});
	}

	/**
	 * Gets the remaining popover data that is not passed when creating a popover
	 *
	 * @param triggerEl
	 * @param options
	 * @returns object
	 */
	function prepPopoverData(triggerEl, options) {
		var box = triggerEl.getBoundingClientRect();

		return {
			content: options.content,
			title: options.title,
			classes: options.classes,
			titleCssOverride: options.titleCssOverride || false,
			placement: options.placement || 'top',
			onload: options.onload || false,
			click: options.click !== false,
			persistent: options.persistent || false,
			contentCssOverride: options.contentCssOverride || false,
			height: box.height,
			width: box.width
		};
	}

	/**
	 * Creates and returns an instance of Popovers based on the trigger element, and all popover options
	 *
	 * @param triggerEl
	 * @param options
	 * @returns {Popover}
	 */
	function createPopoverInstance(triggerEl, options) {
		var allOptions = prepPopoverData(triggerEl, options);

		var newPopover = new Popover(triggerEl, allOptions);

		registerElement(triggerEl, newPopover);

		return newPopover;
	}

	function createIconButtonTemplate(popover) {
		const button = document.createElement('button');
		button.className = 'bhrPopover__close';
		button.setAttribute('type', 'button');
		button.setAttribute('data-dismiss', 'modal');
		button.setAttribute('aria-label', 'Close');
		const icon = document.createElement('ba-icon');
		icon.setAttribute('encore-name', 'xmark-solid');
		icon.setAttribute('encore-size', '12');

		button.appendChild(icon);
		popover.appendChild(button);

		return button;
	}

	/**
	 * Creates and returns the popover element for reference in the Popover class
	 *
	 * @returns {HTMLElement}
	 */
	function createPopoverTemplate() {
		const popover = document.createElement('div');
		popover.className = 'bhrPopover__box bhrPopover__box--encore';

		return popover;
	}

	/* @startCleanup encore */
	/**
	 * Creates and returns the popover element for reference in the Popover class
	 *
	 * @returns {HTMLElement}
	 */
	function createLegacyPopoverTemplate() {
		const popover = document.createElement('div');
		popover.className = 'bhrPopover__box';

		const button = document.createElement('button');
		button.className = 'bhrPopover__close';
		button.setAttribute('type', 'button');
		button.setAttribute('data-dismiss', 'modal');
		button.setAttribute('aria-label', 'Close');
		const span = document.createElement('ba-icon');
		span.setAttribute('name', 'fab-x-12x12');

		button.appendChild(span);
		popover.appendChild(button);

		return popover;
	}

	/* @endCleanup encore */
	/**
	 * Finds new triggers that have not been registered yet.
	 * Triggers may not be direct children of CODE or PRE tags
	 *
	 * @returns {Array}
	 */
	function findNewTriggers() {
		var allTriggers = document.querySelectorAll('[data-pop-content]');
		var newTriggers = [];

		Utils.each(allTriggers, function (trigger) {
			var tagName = trigger.parentNode.tagName.toLowerCase();

			if (!isRegistered(trigger) && tagName != 'code' && tagName != 'pre') {
				newTriggers.push(trigger);
			}
		});

		return newTriggers;
	}

	/**
	 * Gets data from data- attributes
	 * This is used when obtaining data about popovers that are being created by HTML attributes (NON JS)
	 *
	 * @param triggerEl
	 * @returns object
	 */
	function getTriggerDataDash(triggerEl) {
		var css = triggerEl.getAttribute('data-pop-content-css-override');
		try {
			css = JSON.parse(css.replace(/'/g, '"'));
		} catch (e) {
			css = undefined;
		}

		// Stupid IE won't let me use .dataset... so .getAttribute it is
		return {
			content: triggerEl.getAttribute('data-pop-content'),
			title: triggerEl.getAttribute('data-pop-title'),
			placement: triggerEl.getAttribute('data-pop-placement'),
			classes: triggerEl.getAttribute('data-pop-classes'),
			onload: (triggerEl.getAttribute('data-pop-onload')) ? (triggerEl.getAttribute('data-pop-onload') === 'true') : undefined,
			click: (triggerEl.getAttribute('data-pop-click')) ? (triggerEl.getAttribute('data-pop-click') === 'true') : undefined,
			persistent: (triggerEl.getAttribute('data-pop-persistent')) ? (triggerEl.getAttribute('data-pop-persistent') === 'true') : undefined,
			contentCssOverride: css
		};
	}

	// *******************************************************************
	// Private functions for Class (Popover) use only
	// *******************************************************************

	/**
	 * Creates the actual popovers and appends them to the body
	 * PRIVATE USE FUNCTION
	 */
	const createPopover = function () {
		this.popoverEl.setAttribute('placement', this.options.placement);

		if (this.options.classes) {
			const classes = this.options.classes.split(',');
			const {
				popoverEl,
			} = this;

			$.each(classes, function (index, value) {
				Utils.addClass(popoverEl, value);
			});
		}

		if (this.options.title) {
			let title;
			/* @startCleanup encore */
			if (ENCORE_ENABLED) {
				/* @endCleanup encore */
				title = document.createElement('h4');
				title.className = 'bhrPopover__title';
				/* @startCleanup encore */
			} else {
				title = document.createElement('h4');
				title.className = 'bhrPopover__title';
			}
			/* @endCleanup encore */

			title.textContent = this.options.title;

			if (this.options.titleCssOverride) {
				for (let key in this.options.titleCssOverride) {
					// eslint-disable-next-line max-depth
					if (this.options.titleCssOverride.hasOwnProperty(key)) {
						title.style[key] = this.options.titleCssOverride[key];
					}
				}
			}
			const button = createIconButtonTemplate(this.popoverEl);
			const header = document.createElement('div');
			header.className = 'bhrPopover__header';
			header.appendChild(title);
			header.appendChild(button);
			this.popoverEl.appendChild(header);
		} else {
			createIconButtonTemplate(this.popoverEl);
		}

		const content = document.createElement('div');
		content.className = 'bhrPopover__content';
		content.innerHTML = this.options.content;

		if (this.options.contentCssOverride) {
			for (let key in this.options.contentCssOverride) {
				if (this.options.contentCssOverride.hasOwnProperty(key)) {
					content.style[key] = this.options.contentCssOverride[key];
				}
			}
		}

		this.popoverEl.appendChild(content);
		this.popoverEl.style.zIndex = getMaxZIndex() + 10;
		document.body.appendChild(this.popoverEl);
	};

	/* @startCleanup encore */
	/**
	 * Creates the actual popovers and appends them to the body
	 * PRIVATE USE FUNCTION
	 */
	const createLegacyPopover = function () {
		this.popoverEl.setAttribute('placement', this.options.placement);

		if (this.options.classes) {
			var classes = this.options.classes.split(',');
			var popoverEl = this.popoverEl;

			$.each(classes, function (index, value) {
				Utils.addClass(popoverEl, value);
			});
		}

		if (this.options.title) {
			var title;
			/* @startCleanup encore */
			if (ENCORE_ENABLED) {
				/* @endCleanup encore */
				title = document.createElement('h4');
				title.className = 'bhrPopover__title';
				/* @startCleanup encore */
			} else {
				title = document.createElement('h4');
				title.className = 'bhrPopover__title';
			}
			/* @endCleanup encore */

			title.textContent = this.options.title;

			if (this.options.titleCssOverride) {
				for (let key in this.options.titleCssOverride) {
					// eslint-disable-next-line max-depth
					if (this.options.titleCssOverride.hasOwnProperty(key)) {
						title.style[key] = this.options.titleCssOverride[key];
					}
				}
			}

			this.popoverEl.appendChild(title);
		}

		const content = document.createElement('div');
		content.className = 'bhrPopover__content';
		content.innerHTML = this.options.content;

		if (this.options.contentCssOverride) {
			for (let key in this.options.contentCssOverride) {
				if (this.options.contentCssOverride.hasOwnProperty(key)) {
					content.style[key] = this.options.contentCssOverride[key];
				}
			}
		}

		this.popoverEl.appendChild(content);
		this.popoverEl.style.zIndex = getMaxZIndex() + 10;
		document.body.appendChild(this.popoverEl);
	};

	/* @endCleanup encore */
	/**
	 * Positions the popovers when they are shown, and during resize events
	 * PRIVATE USE FUNCTION
	 */
	const positionPopover = function () {
		// update to account for Encore and make it closer
		const triggerPos = BambooHR.Utils.getRelativePosition(this.triggerEl);

		switch (this.options.placement) {
			case 'right':
				this.popoverEl.style.top = `${ triggerPos.top - (this.popoverEl.offsetHeight / 2) + (triggerPos.height / 2) }px`;
				this.popoverEl.style.left = `${ triggerPos.left + triggerPos.width }px`;
				break;

			case 'bottom':
				this.popoverEl.style.top = `${ triggerPos.top + triggerPos.height }px`;
				this.popoverEl.style.left = `${ triggerPos.left - (this.popoverEl.offsetWidth - triggerPos.width) / 2 }px`;
				break;

			case 'left':
				this.popoverEl.style.top = `${ triggerPos.top - (this.popoverEl.offsetHeight / 2) + (triggerPos.height / 2) }px`;
				this.popoverEl.style.left = `${ triggerPos.left - (this.popoverEl.offsetWidth + (DEFAULT.arrowHeight / 2)) }px`;
				break;

			case 'topleft':
				this.popoverEl.style.top = `${ triggerPos.top - (this.popoverEl.offsetHeight / 3) + (triggerPos.height / 2) }px`;
				this.popoverEl.style.left = `${ triggerPos.left - (this.popoverEl.offsetWidth + (DEFAULT.arrowHeight / 2)) }px`;
				break;

			case 'bottomleft':
				this.popoverEl.style.top = `${ triggerPos.top + triggerPos.height }px`;
				this.popoverEl.style.left = `${ triggerPos.left - (this.popoverEl.offsetWidth - triggerPos.width) + 20 }px`;
				break;

			case 'top':
			/* falls through */
			default:
				this.popoverEl.style.top = `${ triggerPos.top - this.popoverEl.offsetHeight }px`;
				this.popoverEl.style.left = `${ triggerPos.left - (this.popoverEl.offsetWidth - triggerPos.width) / 2 }px`;
		}
	};

	/* @startCleanup encore */
	/**
	 * Positions the popovers when they are shown, and during resize events
	 * PRIVATE USE FUNCTION
	 */
	const positionLegacyPopover = function () {

		// update to account for Encore and make it closer
		var triggerPos = BambooHR.Utils.getRelativePosition(this.triggerEl);

		switch (this.options.placement) {
			case 'right':
				this.popoverEl.style.top = triggerPos.top - (this.popoverEl.offsetHeight / 2) + (triggerPos.height / 2) + 'px';
				this.popoverEl.style.left = triggerPos.left + (triggerPos.width + DEFAULT.arrowHeight) + 'px';
				break;

			case 'bottom':
				this.popoverEl.style.top = triggerPos.top + (DEFAULT.arrowHeight + triggerPos.height) + 'px';
				this.popoverEl.style.left = triggerPos.left - (this.popoverEl.offsetWidth - triggerPos.width) / 2 + 'px';
				break;

			case 'left':
				this.popoverEl.style.top = triggerPos.top - (this.popoverEl.offsetHeight / 2) + (triggerPos.height / 2) + 'px';
				this.popoverEl.style.left = triggerPos.left - (this.popoverEl.offsetWidth + DEFAULT.arrowHeight) + 'px';
				break;

			case 'topleft':
				this.popoverEl.style.top = triggerPos.top - (this.popoverEl.offsetHeight / 3) + (triggerPos.height / 2) + 'px';
				this.popoverEl.style.left = triggerPos.left - (this.popoverEl.offsetWidth + DEFAULT.arrowHeight) + 'px';
				break;

			case 'bottomleft':
				this.popoverEl.style.top = triggerPos.top + (DEFAULT.arrowHeight + triggerPos.height) + 'px';
				this.popoverEl.style.left = triggerPos.left - (this.popoverEl.offsetWidth - triggerPos.width) + 20 + 'px';
				break;

			case 'top':
			/* falls through */
			default:
				this.popoverEl.style.top = triggerPos.top - (DEFAULT.arrowHeight + this.popoverEl.offsetHeight) + 'px';
				this.popoverEl.style.left = triggerPos.left - (this.popoverEl.offsetWidth - triggerPos.width) / 2 + 'px';
		}
	};

	/* @endCleanup encore */
	/**
	 * Sets events on each trigger element for showing and hiding the popover
	 * PRIVATE USE FUNCTION
	 */
	var setEvents = function () {
		var self = this;

		// Show the popover when the trigger is clicked, and close other popovers
		this.triggerEl.addEventListener('click', function (e) {
			e.stopPropagation();
			closeAllOtherPopovers.call(self);

			if (Utils.hasClass(self.popoverEl, DEFAULT.hideClass)) {
				self.show();
			}
		});

		// Close other popovers if clicking inside of a popover
		// Useful when some popovers are "persistent"
		this.popoverEl.addEventListener('click', function (e) {
			e.stopPropagation();
			closeAllOtherPopovers.call(self);
		});

		// Close the popover when the close icon is clicked
		var close = this.popoverEl.querySelector('button');
		close.addEventListener('click', function (e) {
			e.stopPropagation();
			self.hide();
		});
	};

	/**
	 * Closes all other popovers.
	 */
	var closeAllOtherPopovers = function () {
		var self = this;

		Popover.eachPopover(function (popover) {
			if (!popover.options.persistent &&
				!Utils.hasClass(popover.popoverEl, DEFAULT.hideClass) &&
				self.triggerEl !== popover.triggerEl) {

				popover.hide();
			}
		});
	};

	// *******************************************************************
	// Popover Class and chain-able methods
	// *******************************************************************

	/**
	 * Popover Constructor
	 *
	 * @param triggerEl
	 * @param options
	 * @constructor
	 */
	function Popover(triggerEl, options) {
		this.triggerEl = triggerEl;
		this.options = options;

		this.popoverEl = /* @startCleanup encore */ENCORE_ENABLED ? /* @endCleanup encore */createPopoverTemplate()/* @startCleanup encore */ : createLegacyPopoverTemplate()/* @endCleanup encore */;

		this.suppressed = !options.click;
		this.showing = true;

		this.onShowFns = [];
		this.onHideFns = [];

		/* @startCleanup encore */
		if (ENCORE_ENABLED) {
			/* @endCleanup encore */
			createPopover.call(this);
			/* @startCleanup encore */
		} else {
			createLegacyPopover.call(this);
		}
		/* @endCleanup encore */
		setEvents.call(this);
		/* @startCleanup encore */
		if (ENCORE_ENABLED) {
			/* @endCleanup encore */
			positionPopover.call(this);
			/* @startCleanup encore */
		} else {
			positionLegacyPopover.call(this);
		}
		/* @endCleanup encore */

		if (!this.options.onload) {
			this.hide();
		}
	}

	/**
	 * Positions a popover in the UI
	 * chain-able
	 *
	 * @returns {Popover}
	 */
	Popover.prototype.position = function () {
		/* @startCleanup encore */
		if (ENCORE_ENABLED) {
			/* @endCleanup encore */
			positionPopover.call(this);
			/* @startCleanup encore */
		} else {
			positionLegacyPopover.call(this);
		}
		/* @endCleanup encore */
		return this;
	};

	/**
	 * Show a popover if it is not suppressed
	 * chain-able
	 *
	 * @returns {Popover}
	 */
	Popover.prototype.show = function () {
		/* @startCleanup encore */
		if (ENCORE_ENABLED) {
			/* @endCleanup encore */
			positionPopover.call(this);
			/* @startCleanup encore */
		} else {
			positionLegacyPopover.call(this);
		}
		/* @endCleanup encore */
		if (!this.suppressed && !this.showing) {
			Utils.removeClass(this.popoverEl, DEFAULT.hideClass);
			this.showing = true;

			var scope = this;
			Utils.each(this.onShowFns, function (fn) {
				fn(scope);
			});
		}
		return this;
	};

	/**
	 * Hides a popover
	 * chain-able
	 *
	 * @returns {Popover}
	 */
	Popover.prototype.hide = function () {
		if (this.showing) {
			Utils.addClass(this.popoverEl, DEFAULT.hideClass);
			this.showing = false;

			var scope = this;
			Utils.each(this.onHideFns, function (fn) {
				fn(scope);
			});
		}
		return this;
	};

	/**
	 * Keeps a popover from being able to be shown
	 * chain-able
	 *
	 * @returns {Popover}
	 */
	Popover.prototype.suppress = function () {
		this.hide();
		this.suppressed = true;
		return this;
	};

	/**
	 * Removes the suppress effect. Allows a popover to show up again
	 * chain-able
	 *
	 * @returns {Popover}
	 */
	Popover.prototype.release = function () {
		this.suppressed = false;
		return this;
	};

	/**
	 * Adds a function to be called when the popover shows
	 * chain-able
	 *
	 * @param fn
	 * @returns {Popover}
	 */
	Popover.prototype.onShow = function (fn) {
		if (typeof fn === 'function') {
			this.onShowFns.push(fn);
		}
		return this;
	};

	/**
	 * Removes a function from being called when the popovers shows. Anonymous functions cannot be removed.
	 * chain-able
	 *
	 * @param fn
	 * @returns {Popover}
	 */
	Popover.prototype.offShow = function (fn) {
		var index = this.onShowFns.indexOf(fn);
		if (index > -1) {
			this.onShowFns.splice(index, 1);
		}
		return this;
	};

	/**
	 * Adds a function to be called when the popover hides
	 * chain-able
	 *
	 * @param fn
	 * @returns {Popover}
	 */
	Popover.prototype.onHide = function (fn) {
		if (typeof fn === 'function') {
			this.onHideFns.push(fn);
		}
		return this;
	};

	/**
	 * Removes a function from being called when the popovers hides. Anonymous functions cannot be removed.
	 * chain-able
	 *
	 * @param fn
	 * @returns {Popover}
	 */
	Popover.prototype.offHide = function (fn) {
		var index = this.onHideFns.indexOf(fn);
		if (index > -1) {
			this.onHideFns.splice(index, 1);
		}
		return this;
	};

	/**
	 * Destroys a Popover. Removes the popover element, and removes the triggerElement from the registry
	 * Also removes any data-pop-* attributes on the element
	 * Does not return the Popover instance (`this`)
	 */
	Popover.prototype.destroy = function () {
		var scope = this;

		if (this.popoverEl) {
			this.popoverEl.parentNode.removeChild(this.popoverEl);
		}

		// remove all the data-pop-* attributes to avoid creating a new popover after an jquery ajax calls for example
		[].slice.call(this.triggerEl.attributes).forEach(function (attr) {
			if ((/^data-pop-/).test(attr.name)) {
				scope.triggerEl.removeAttribute(attr.name);
			}
		});

		unRegisterElement(this.triggerEl);
	};

	// *******************************************************************
	// General Library Interaction Methods
	// *******************************************************************

	/**
	 * Create a new popover using JavaScript.
	 *
	 * @param selector
	 * @param options
	 * @returns {*}
	 */
	Popover.create = function (selector, options) {
		var triggerEl = (selector.nodeType && selector.nodeType == 1) ? selector : document.querySelector(selector);

		if (!triggerEl) {
			console.warn('Whoa! There\'s no element on the page with a selector of: ' + selector);
			return false;
		}

		if (isRegistered(triggerEl)) {
			console.warn('There is already a popover for element "' + selector + '". The popover instance has been returned to you.');

			return Popover.selectPopovers(selector);
		}

		return createPopoverInstance(triggerEl, options);
	};

	/**
	 * Creates new popovers based on data- attributes that exists on the page and do not need references.
	 */
	Popover.init = function () {
		var newTriggers = findNewTriggers();

		Utils.each(newTriggers, function (triggerEl) {
			var options = getTriggerDataDash(triggerEl);
			createPopoverInstance(triggerEl, options);
		});

		Popover.eachPopover(function (popover) {
			popover.position();
		});

		Popover.purge();
	};

	/**
	 * Search available popovers using a selector for a trigger element
	 * If popover instances are found, they are returned in an array.
	 *
	 * @param selector
	 * @returns {Array}
	 */
	Popover.selectPopovers = function (selector) {
		var registered = [];
		Utils.each(document.querySelectorAll(selector), function (el) {
			var instance = isRegistered(el);

			if (instance) {
				registered.push(instance);
			}
		});
		return registered;
	};

	/**
	 * Loops through each popover that is registered. Runs a callback function on the popover
	 *
	 * @param fn
	 */
	Popover.eachPopover = function (fn) {
		Utils.each(elementRegister, function (register) {
			fn(register.instance);
		});
	};

	/**
	 * Removes the Popovers that are no longer being used.
	 * Removes Popover element from DOM
	 * Deletes from the elementRegister
	 */
	Popover.purge = function () {
		elementRegister = elementRegister.filter(function (reg) {
			var isUsed = document.body.contains(reg.element.parentNode);
			if (!isUsed) {
				var tip = reg.instance.popoverEl;
				tip.parentNode.removeChild(tip);
			}
			return isUsed;
		});
	};

	// *******************************************************************
	// Window Event methods
	// *******************************************************************

	/**
	 * When window loads or is refocused, all popovers are positioned after 200ms (stupid IE...)
	 */
	window.addEventListener('load', function () {
		window.setTimeout(function () {
			Popover.eachPopover(function (popover) {
				popover.position();
			});
		}, 200);
	});

	window.addEventListener('focus', function () {
		window.setTimeout(function () {
			Popover.eachPopover(function (popover) {
				popover.position();
			});
		}, 200);
	});

	/**
	 * Re-positions all popovers on page-resize
	 */
	// Don't kill the browser by resizing every pixel, just resize after 10ms has passed of no resizing events
	var resizeTimer;
	window.addEventListener('resize', function () {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {

			Popover.eachPopover(function (popover) {
				popover.position();
			});

		}, 10);
	});

	/**
	 * When any click on the body occurs, close all popovers that are not persistent.
	 * Only call the .hide() method on popovers that are actually hidden, otherwise onHide functions are ran
	 */
	document.body.addEventListener('click', function () {
		Popover.eachPopover(function (popover) {
			if (!popover.options.persistent && !Utils.hasClass(popover.popoverEl, DEFAULT.hideClass)) {
				popover.hide();
			}
		});
	});

	/**
	 * Returns the available methods for general library interaction
	 */
	return {
		create: Popover.create,
		init: Popover.init,
		selectPopovers: Popover.selectPopovers,
		eachPopover: Popover.eachPopover,
		purge: Popover.purge
	};

})();
