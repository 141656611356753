
/*
* Internally built jQuery plugin for inline editing with consitent styles
* 
* @requires xmlEntities()
* 
* @usage
* 
* $(selector).inlineEdit(saveUrl, {options});
* 
* @example
* 
* <span id="myId9" class="editSpan">myValue</span>
* 
* $(".editSpan").inlineEdit(mysave.php);
* or 
* $(".editSpan").inlineEdit(mysave.php, {highlightParent: "div:first", onSave: mySaveHandler });
* 
* The number in the span id (9) will be passed to the saveUrl along with the value submitted
* highlightParent defines what parent element will get highlighted after saving for user feedback
*
* The server response is sent as an argument to the onSave handler. The server response needs to be a json object.
* If response.error is defined the inline edit falls back to the original value and you can display the error
* in the onSave handler.
* 
*/

(function($) {
	$.inlineEdit = function() {
	}

	$.extend($.inlineEdit, {

		saveUrl: "",
		parent: undefined,
		originalText: "",
		originalElement: undefined,
		state: {
			editing: false,
			active: undefined
		},
		options: {
			// The .parent selector that gets highlighted on save
			highlightParent: "tr:first",
			// Event handling
			onEdit: function() {},
			onCancel: function() {},
			onSave: function(response) {
				if (response.error) {
					alert(response.error);
				}
			}
		},

		// The save function is bound to the form
		save: function(id, form) {
			var localJqueryObj = this;
			var value = form.find('input:first').val();
			var id = Number(id);
			if (id == 0) {
				alert("Error: No ID defined");
				return false;
			}
			if (undefined != value && value != '') {
				$.post(
					this.saveUrl,
					{"id": id, "value":value, "ajax":1},
					function(response) {
						localJqueryObj.update(response, value);
					},
					"json"
				);
			}
			return false;
		},

		update: function(response, value) {
			this.parent.empty();
			if (!response.error) {
				this.originalElement.text(value).appendTo(this.parent);
			} else {
				this.originalElement.appendTo(this.parent);
			}
			$(this.parent).parents(this.options.highlightParent).effect("highlight", {}, 1500);
			this.state.editing = false;
			this.options.onSave(response);
		},

		edit: function() {
			var parent = $(this).parent();
			if (!this.state.editing) {
				var localJqueryObj = this;
				this.parent = parent;
				$(this).removeClass('inlineEdit-hover');
				this.originalElement = $(this).detach();
				this.originalText = $(this).text();
				var regex = /[0-9]+/;
				var id = regex.exec($(this).attr("id"));
				parent.html(
					'<form id="inlineForm'+id+'"><input type="text" name="change" class="text inlineEditInput" value="'+xmlEntities(this.originalText)+'" /><br />' +
					'<input type="submit" value="Save" class="button btnSmall" />'+
					' or <a id="inlineFormCancel'+id+'" href="javascript:void(0)" class="canceledit-link">Cancel</a></form>'
				);
				parent.find('input:first').select().focus();
				$('#inlineForm'+id).submit(function() {
					localJqueryObj.save(id, $(this))
					return false;
				});
				$('#inlineFormCancel'+id).click(function() {
					localJqueryObj.cancel();
					return false;
				});
				this.state.editing = true;
				this.state.active = this;
				this.options.onEdit();

			}
		},

		escCancel: function(e) {
			if (e.keyCode == 27) {
				$.inlineEdit.state.active.cancel();
			}
		},

		cancel: function() {
			if (this.state.editing) {
				this.parent.empty();
				this.originalElement.appendTo(this.parent);
				this.state.editing = false;
				this.options.onCancel();
			}
		}

	});

	$.fn.inlineEdit = function(saveUrl, options) {
		$(document).bind('keypress', $.inlineEdit.escCancel);
		return this.each(function() {
			var obj = $(this);
			obj = $.extend(obj, $.inlineEdit);
			if (undefined != options) {
				obj.options = $.extend(obj.options, options);
			}
			obj.saveUrl = saveUrl;
			obj.hover(
				function() {if (!obj.state.editing) {$(this).addClass('inlineEdit-hover')}},
				function() {$(this).removeClass('inlineEdit-hover')}
			)
			.attr("title", "Click to edit")
			.attr("alt", "Click to edit");
			return obj.click(function() {
				obj.edit();
			});
		});
	};

})(jQuery);
