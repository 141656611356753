import { FC, useEffect, useState } from 'react';

import { isEnabled } from 'FeatureToggle.util';

import { getIsAdmin } from './utils';
import { BenchmarkingModalOnSubmitService, BenchmarkingStatusUpdateModalProps, BenchmarkingStatuses } from "../types";
import { benchmarkingOptInService, benchmarkingOptOutService, benchmarkingStatusService } from '../services';
import { BenchmarkingOptInModal, BenchmarkingOptOutModal } from '../modals';

const serviceFactory = (status: BenchmarkingStatuses): BenchmarkingModalOnSubmitService | undefined => {
    switch(status) {
        case 'prompt-opt-in':
            return benchmarkingOptInService;
        case 'prompt-opt-out':
            return benchmarkingOptOutService;
    }
}

const modalFactory = (status: BenchmarkingStatuses): FC<BenchmarkingStatusUpdateModalProps> | undefined =>  {
    switch(status) {
        case 'prompt-opt-in':
            return BenchmarkingOptInModal;
        case 'prompt-opt-out':
            return BenchmarkingOptOutModal;
    }
}

export const useBenchmarkingStatus = (launchModal: (service: BenchmarkingModalOnSubmitService) => void) => {
    const [status, setStatus] = useState<BenchmarkingStatuses>();
    useEffect(() => {
        if (getIsAdmin() && isEnabled('BENCHMARKING_OPT_OUT')) {
            benchmarkingStatusService().then(newStatus => {
                const service = serviceFactory(newStatus);
                if (service) {
                    launchModal(service);
                }
                setStatus(newStatus);
            });
        }
    }, []);
    return { BenchmarkingStatusUpdateModal: modalFactory(status) };
}
