/* @startCleanup encore */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import Ajax from '@utils/ajax';
import { QuickstartBanner } from './quickstart-banner';
import { PayrollBanner } from './payroll-banner';
import { CoreImplementationBanner } from './core-implementation-banner';
import { BannerData, SetupType } from './types';
import { UPDATE_BANNER_DATA_EVENT } from './constants';
import { removeBannerLocalStorage } from './utils';

export function SetupSummaryBanner({ stepKey, dataPath, type, ...bannerData }: BannerData) {
	const [stepData, setStepData] = useState(null);

	const getStepDataUrl = (type, stepKey) => {
		if (type === SetupType.PAYROLL) {
			return `/registration/payroll/steps/${stepKey}?clientId=${bannerData.clientId}`;
		}

		return `/setup/${type}/steps/${stepKey}`;
	};

	useEffect(() => {
		const getStepData = (e?) => {
			if (!e || e?.detail?.stepKey === stepKey) {
				const stepDataUrl = getStepDataUrl(type, stepKey);

				if (type !== 'core') {
					Ajax.get(stepDataUrl)
						.then((resp) => {
							const taskData = get(resp.data, dataPath) || resp.data;
							setStepData(taskData);
						})
						.catch(() => {
							removeBannerLocalStorage();
						});
				} else {
					setStepData({});
				}
			}
		};

		if (stepKey) {
			getStepData();
			document.addEventListener(UPDATE_BANNER_DATA_EVENT, getStepData);
		}

		return () => {
			document.removeEventListener(UPDATE_BANNER_DATA_EVENT, getStepData);
		};
	}, []);

	useEffect(() => {
		if (!stepData) {
			// Update nav position
			document.dispatchEvent(new Event('updateNavPosition'));
		}
	}, [stepData]);

	const handleClose = () => {
		removeBannerLocalStorage();
		setStepData(null);
	};

	let Banner;
	switch (type) {
		case SetupType.QUICKSTART:
			Banner = QuickstartBanner;
			break;
		case SetupType.PAYROLL:
			Banner = PayrollBanner;
			break;
		case SetupType.CORE_IMPLEMENTATION:
			Banner = CoreImplementationBanner;
			break;
		default:
			break;
	}

	if (!Banner || !stepData) {
		return null;
	}

	const bannerProps = {
		...bannerData,
		...stepData,
		onClose: handleClose,
	};

	return <Banner {...bannerProps} />;
}
/* @endCleanup encore */
