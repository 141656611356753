import React from 'react';

export function IdentityVerificationModalContent(props) {
	const {
		verificationCode,
	} = props;

	return (
		<div style={{ textAlign: 'center' }}>
			<h4>
                Thank you for contacting BambooHR support!
			</h4>
			<div>
                Please give this code to your support agent so we can continue to help you.
			</div>
			<h1>
				{ verificationCode }
			</h1>
			<div>
                If you did not request this code, go ahead and dismiss this message.
			</div>
		</div>
	);
}
