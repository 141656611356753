import { BEM } from '@utils/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const bem = new BEM('LockScreen');

export const HEADER = $.__('Just to be safe, we logged you out.');
export const PASSWORD_INSTRUCTION = $.__('Enter your password to pick up where you left off.');
export const SAML_INSTRUCTION = $.__('Log back in to pick up where you left off.');
export const PASSWORD_PLACEHOLDER = $.__('Password');

export const DEFAULT_BUTTON_TEXT = $.__('Log in');
export const GOOGLE_BUTTON_TEXT = $.__('Log in with Google');
export const MICROSOFT_BUTTON_TEXT = $.__('Log in with Microsoft');
export const QUICKBOOKS_SSO_BUTTON_TEXT = $.__('Log in with QuickBooks Online Payroll');
export const OKTA_BUTTON_TEXT = $.__('Log in with Okta');
export const ONELOGIN_BUTTON_TEXT = $.__('Log in with OneLogin');
export const GH_BUTTON_TEXT = $.__('Log Back In');


export const GOOGLE_BUTTON_ICON = 'fab-google-login-20x20';
export const LOCK_ICON = ifFeature('encore', 'lock-regular', 'fab-sleeping-lock-63x58');
export const ONE_LOGIN_BUTTON_ICON = 'fab-onelogin-20x20';
export const PASSWORD_INPUT_ICON = 'fab-key-18x18';
