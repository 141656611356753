import 'dotdotdot';

$(document).ready(function () {
	$('.dot-ellipsis').dotdotdot({wrap: 'letter'});
});

$(document).ajaxSuccess(function () {
	window.setTimeout(function () {
		$('.dot-ellipsis').dotdotdot({wrap: 'letter'});
	});
});
