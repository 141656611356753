import Pusher from 'pusher-js';

let socketConnection = null;

function getSocketConnection(connectionObject) {
	return new Promise((resolve, reject) => {
		if (socketConnection && socketConnection.connection.state === 'connected') {
			resolve(socketConnection);
			return;
		}

		socketConnection = new Pusher(connectionObject.pusherKey, {
			cluster: connectionObject.pusherCluster,
			forceTLS: true,
			authEndpoint: connectionObject.authEndpoint,
			auth: {
				headers: {
					'X-CSRF-Token': connectionObject.csrfToken,
				}
			}
		});

		socketConnection.connection.bind('connected', () => {
			resolve(socketConnection);
		});

		// The connection is temporarily unavailable.
		// In most cases this means that there is no internet connection. It could also mean that Channels is down
		socketConnection.connection.bind('unavailable', reject);

		// Channels is not supported by the browser.
		// This implies that WebSockets are not natively available and an HTTP-based transport could not be found.
		socketConnection.connection.bind('failed', reject);

		// The Channels connection was previously connected and has now intentionally been closed.
		socketConnection.connection.bind('disconnected', reject);
	})
		.catch((reason) => {
			Promise.reject(`Connection to external service failed...${ reason}`);
		});
}

function isValidConnectionObject(connectionObject) {
	if (typeof connectionObject === 'object') {
		const { channel } = connectionObject;

		let events = true;
		if (connectionObject.events) {
			events = (typeof connectionObject.events === 'object' &&
			Object.keys(connectionObject.events).length >= 0);
		}

		return (
			typeof channel === 'string' &&
			channel.trim().length > 0 &&
			events
		);
	}
	return false;
}

function createConnectionObject(socketConnection, pusherChannel, channelDetails) {
	const {channel, matter} = channelDetails;
	return {
		channelName: channel,
		userId: matter,
		channel: pusherChannel,
		unsubscribe: () => socketConnection.unsubscribe(channel),
		on(event, fn) {
			if (!event || !fn) {
				return;
			}
			pusherChannel.bind(event, fn);
		},
		off(event, fn) {
			pusherChannel.unbind(event, fn);
		}
	};
}

function setupExpiredListener(socketConnection) {
	document.addEventListener('AppSession:expired', () => {
		socketConnection.disconnect();
	}, { once: true });
}

function setupLoggedInListener(connectionObjects) {
	document.addEventListener('AppSession:loggedIn', () => {
		connectionObjects.forEach((connectionObject) => {
			subscribeToChannel(connectionObject);
		});
	}, { once: true });
}

export async function subscribeToChannel(connectionObject) {

	if (!isValidConnectionObject(connectionObject)) {
		return Promise.reject('No valid connection objects were used...');
	}

	const socketConnection = await getSocketConnection(connectionObject);

	if (!socketConnection) {
		return Promise.reject('Socket connection unavailable, failed, or disconnected...');
	}

	const channel = socketConnection.subscribe(connectionObject.channel);
	return new Promise((resolve) => {
		channel.bind('pusher:subscription_succeeded', () => {
			if (connectionObject.events) {
				Object
					.keys(connectionObject.events)
					.forEach((event) => {
						channel.bind(`client-${ event }`, connectionObject.events[event]);
					});
			}

			setupExpiredListener(socketConnection);
			setupLoggedInListener(connectionObject);

			resolve(createConnectionObject(socketConnection, channel, connectionObject));
		});
	});
}

export default {
	subscribeToChannel,
};
