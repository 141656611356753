/********************************************
 Chosen extension for modals

 This component subscribes to channels from
 chosen (chosen.jquery.js)

 It enables dropdowns to break out of modals
 ********************************************/

export default {

	/**
	 * Chosen initialization
	 * @returns {undefined}
	 */
	init: function () {
		this.enableListeners();
		BambooHR.subscribe('chosen:open:after', this.onOpen, this);
		BambooHR.subscribe('chosen:close:after', this.onClose, this);
	},

	// Store for current chosen dropdown
	_currentChosen: null,

	//Store for current overflow parents
	_currentHidden: null,

	_isStatic: false,

	//Selector classes
	_selectors: {
		chosenContainer: '.chzn-container',
		chosenLink: '.chzn-single',
		chosenScroll: '.chzn-results, .active-result',
		chosenSearch: '.chzn-search input',
		uiDialog: '.ui-dialog',
		simpleModalBody: '.SimpleModal__body',
		bhrPreview: '.BhrPreview',
		simpleModal: '.SimpleModal'
	},

	//Usable classes
	_classes: {
		chosenShow: 'chzn-single-with-drop',
		chosenContainerUp: 'chzn-with-dropup',
		chosenDropUp: 'chzn-dropup'
	},

	/**
	 * Test if Chosen dropdown is inside a modal (and not inside a bhrPreviewer)
	 * @param element $dropDown The JQuery element passed from Chosen
	 * @returns {boolean}       True/False whether modal is appropriate for new static position
	 */
	shouldBreakOut: function ($dropDown) {

		if ($dropDown.closest(this._selectors.simpleModal).hasClass('js-no-chosen-breakout')) {
			return false;
		}

		return (
			(
				$dropDown.closest(this._selectors.uiDialog).length ||
				$dropDown.closest(this._selectors.simpleModalBody).length ||
				$dropDown.closest('.js-chosen-breakout').length
			) &&
			$dropDown.closest(this._selectors.bhrPreview).length < 1
		)
	},

	/**
	 * Behavior on chosen open:after
	 * @param element $dropDown The JQuery element passed from Chosen
	 * @returns {undefined}
	 */
	onOpen: function ($dropDown) {
		// Check if dropdown needs to break out.
		if (this.shouldBreakOut($dropDown)) {
			this._currentChosen = this.Chosen($dropDown);

			//Checking if we are in a modal vs somewhere else like a data table.
			if ($dropDown.closest('.js-simplemodal-container, .ui-dialog').length) {
				//For modals this order is needed so we see the full drop down even if it goes outside of the modal
				this.setDropDownPosition(this._currentChosen);
				this.toggleModalScrolling(false);
			} else {
				//For everything else we need to disable the scroll bar and get it out of the way before setting position
				this.toggleModalScrolling(false);
				this.setDropDownPosition(this._currentChosen);
			}
		}
	},

	/**
	 * Behavior on chosen close:before
	 * @returns {undefined}
	 */
	onClose: function ($dropDown) {
		// Check if dropdown needs to break out.
		if (this.shouldBreakOut($dropDown)) {

			if ($dropDown === undefined || this._currentChosen === null) {
				this.enableScrolling();
				return;
			}

			// Check if the closed dropdown is the current dropdown
			if ($dropDown.is(this._currentChosen.dropDown)) {
				this.enableScrolling();
			}
		}
	},

	/**
	 * Constructor
	 * @returns {Object} chosen An object contianing related chosen elements
	 */
	Chosen: function($dropDown) {
		var chosen = {
			dropDown: $dropDown,
			container: $dropDown.parent(this._selectors.chosenContainer)
		};

		return chosen;
	},

	/**
	 * set the Position of the Dropdown
	 * @param Object Chosen
	 * @returns {undefined}
	 */
	setDropDownPosition: function (Chosen) {

		if (this._currentChosen) { // You must exist!

			Chosen.dropDown.css({position: 'fixed'});

			var ref = Chosen.container[0].getBoundingClientRect();
			var dropDownHeight = Chosen.dropDown[0].scrollHeight;
			var scrollContent = Chosen.container.closest(".js-chosen-container");
			var viewPortHeight = window.innerHeight;
			var top;

			if (scrollContent.length > 0) {
				viewPortHeight = scrollContent[0].getBoundingClientRect().bottom;
			}

			if (dropDownHeight > 269) {
				dropDownHeight = 269; // Because it is the max!
			}

			// DropUp Functionality
			if ((ref.bottom + dropDownHeight) > viewPortHeight) {
				// ATTN: BEN
				top = ref.top - dropDownHeight - 1 + 'px';
				Chosen.container.addClass(this._classes.chosenContainerUp);
				Chosen.dropDown.addClass(this._classes.chosenDropUp);
			} else {
				// ATTN: BEN
				top = ref.bottom - 1 + 'px';
				Chosen.container.removeClass(this._classes.chosenContainerUp);
				Chosen.dropDown.removeClass(this._classes.chosenDropUp);
			}

			// Position Element
			Chosen.dropDown.css({
				top: top,
				left: ref.left + 'px',
				bottom: 'auto'
			});
		}
	},

	/**
	 * Hide the dropdown
	 * @param Object Chosen
	 * @returns {undefined}
	 */
	hideDropDown: function(Chosen) {
		if (this._currentChosen) {
			Chosen.dropDown.siblings(this._selectors.chosenLink).removeClass(this._classes.chosenShow);
			Chosen.dropDown.css({ left: '-9000px'});
			this.onClose(this._currentChosen.dropDown);
		}
	},

	toggleModalScrolling: function (enable) {
		// ATTN: BEN | Temporary Fix for custom dropdowns
		if (this._currentChosen && this._currentChosen.dropDown.closest('div.js-simplemodal-container').hasClass('no-chosen-breakout')) {
			return;
		}

		var elements = [];

		// If a currentChosen Exists
		if (this._currentChosen !== null) {
			var node = this._currentChosen.container[0];

			// Loop up through the DOM
			while (node) {
				if (node.style) {
					// eslint-disable-next-line max-depth
					if (node.scrollHeight > node.clientHeight + 4 && node.tagName !== 'HTML' && node.tagName !== 'BODY') {
						elements.unshift(node);
					}
				}
				node = node.parentNode;
			}

			this._currentHidden = elements;
		}

		if (this._currentHidden !== null && this._currentHidden.length) {
			let scrollType = enable ? 'scroll' : 'hidden';

			for (var i = 0; i < this._currentHidden.length; i++) {
				this._currentHidden[i].style.overflowY = scrollType;
			}

			if (!enable) {
				this._currentHidden = null; // Clear the store
			}
		}
	},

	enableScrolling: function () {
		this.toggleModalScrolling(true); // Move out!
		this._currentChosen = null;
	},

	/**
	 * Enable Chosen related listeners
	 * @returns {undefined}
	 */
	enableListeners: function() {
		var that = this;

		// On resize set Position
		$(window).resize(function() {
			that.hideDropDown(that._currentChosen);
		});

		// Check position when dropdown results change
		$(this._selectors.chosenSearch).on('keyup', function() {
			that.setDropDownPosition(that._currentChosen);
		});
	}
};
