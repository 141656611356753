import {
	StandardModal,
	Button
} from '@bamboohr/fabric';

import { IdentityVerificationModalContent } from './identity-verification-modal-content';   

export function IdentityVerificationModal(props) {
	const {
		isOpen,
		onClose,
		verificationCode,
	} = props;

	const header = <StandardModal.Header hasCloseButton={true} title="Customer Identity Verification" />;

	const footer = <StandardModal.Footer actions={[<Button clickAction={ onClose } key="primary" type="button">{$._('Dismiss')}</Button>]} />;

	return (
		<StandardModal isOpen={ isOpen } onRequestClose={ onClose } title="Customer Identity Verification">
			<StandardModal.Body renderFooter={ footer } renderHeader={ header }>
				<StandardModal.Constraint spacingOverrides={{
					bottom: StandardModal.Constraint.Spacing.BIGGIE, 
					top: StandardModal.Constraint.Spacing.MEDIUM,
				}}
				>
					<IdentityVerificationModalContent verificationCode={ verificationCode } />
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
