import { subscribeToChannel } from './socket';

function buildChannelName(connectionObject) {
	const {companyId, id} = window.SESSION_USER;
	const { subject } = connectionObject;
	const channel = ['private', companyId, subject, id];
	return channel.join('-');
}

function attachPusherInfo(connectionObject) {
	connectionObject.pusherKey = window.PUSHER_AUTH_KEY;
	connectionObject.pusherCluster = window.PUSHER_CLUSTER;
	connectionObject.csrfToken = window.CSRF_TOKEN;
	connectionObject.authEndpoint = '/pusher/auth';
}

export function subscribe(connectionObject) {
	connectionObject.channel = buildChannelName(connectionObject);
	attachPusherInfo(connectionObject);
	return subscribeToChannel(connectionObject);
}

export default { subscribe };
