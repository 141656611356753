import algoliasearch from 'algoliasearch/lite';
import Ps from 'perfect-scrollbar';
import { isEnabled } from 'FeatureToggle.util';
import './HelpCenter.styl';
import React, { Fragment } from 'react';

// eslint-disable-next-line no-restricted-imports
import { render } from 'react-dom';
import { Icon } from '@bamboohr/fabric';

class HelpCenter extends React.Component {
	state = { hits: [] };

	emptyResult() {
		return (
			<li className="HelpCenterListItem HelpCenterListItem--clean HelpCenterListItem--empty">
				<Icon name="fab-triangle-exclamation-54x54"/>
				{$.__('Sorry, no results were found.')}
			</li>
		);
	}

	searchHit(hit, idx) {
		let url = '';
		let name = hit.name;
		if (hit.type === 'zendesk' || hit.type === 'salesforce') {
			url = hit.url;

			return (
				<li key={idx} className="ManageNavItemDropdown__item">
					<a className="ManageNavItemDropdown__itemLink ManageNavItemDropdown__itemLink--zendesk"
						href={url}
						target="_blank"
						rel="noopener noreferrer"
					>
						<span key="1" className="ManageNavItemDropdown__itemIcon">
							<Icon name="fab-file-12x14"/>
						</span>
						<span key="2" className="ManageNavItemDropdown__itemText">
							<div>{name}</div>
							<div className="ManageNavItemDropdown__itemText-detail"
								dangerouslySetInnerHTML={ {__html: hit._snippetResult.body.value} }
							/>
						</span>
					</a>
				</li>
			);
		}
		url = `/home/?pendo=${ hit.objectID }`;
		return (
			<li key={idx} className="ManageNavItemDropdown__item">
				<a className="ManageNavItemDropdown__itemLink" href={url}>
					<span key="1" className="ManageNavItemDropdown__itemIcon">
						<Icon name="fab-play-circle-14x14"/>
					</span>
					<span key="2" className="ManageNavItemDropdown__itemText truncate">
						{name}
					</span>
				</a>
			</li>
		);
	}

	render() {
		if (this.state.hits && this.state.hits.length === 0) {
			return this.emptyResult();
		} else {
			return this.state.hits.map((el, idx) => this.searchHit(el,idx));
		}
	}
}

let searcher = (phrase) => { };
const HELP_CENTER = document.querySelector('#help-center');
if (HELP_CENTER) {
	let defaults = {
		wheelPropagation: false,
		suppressScrollX: true,
		scrollYMarginOffset: 5,
		minScrollbarLength: 20
	};

	Ps.initialize(HELP_CENTER, defaults);

	const client = algoliasearch('LOK4TOOGPX', '3e4a64f1e440458ae8a07a3027f805ce');
	const index = client.initIndex('SalesForce Help Desk');
	let app;
	searcher = phrase => index.search(phrase, { hitsPerPage: 10 }).then(
		({ hits } = {}) => {
			if (!app) {
				app = render(<HelpCenter />, HELP_CENTER);
			}
			app.setState({hits}, () => {
				Ps.update(HELP_CENTER);
			});
		}
	);
	let searchInput = document.getElementById('search-input');
	if (searchInput) {
		searchInput.addEventListener("input", (event) => { searcher(event.target.value); });
	}
}


export function search(phrase) {
	searcher(phrase);
}
