import { getFormData } from 'BambooHR.util';
import Ajax from '@utils/ajax';
import {
	ENDPOINT,
	GOOGLE_TYPE,
	OKTA_TYPE,
	ONE_LOGIN_TYPE,
	PASSWORD_TYPE,
	SAML_TYPE,
	MICROSOFT_TYPE,
	QUICKBOOKS_SSO_TYPE
} from './constants';
import { isOnboardingUser } from './index';

export function getLoginType() {
	return Ajax.get(`${ENDPOINT}/get_login_type`);
}

export function getLoginService(type) {
	switch (type) {
		case GOOGLE_TYPE:
			return doGoogleLogin;
		case PASSWORD_TYPE:
			return doPasswordLogin;
		case OKTA_TYPE:
		case ONE_LOGIN_TYPE:
		case SAML_TYPE:
			return doSamlLogin;
		case MICROSOFT_TYPE:
			return doMicrosoftLogin;
		case QUICKBOOKS_SSO_TYPE:
			return doQuickBooksLogin;
		default:
			return null;
	}
}

export function doGoogleLogin(opts) {
	const { onSuccess, onError } = opts;

	const openIdConnectIss = $('input#openidConnectIss').val();
	const openIdConnectSub = $('input#openidConnectSub').val();
	const data = { openIdConnectIss, openIdConnectSub };

	$.ajax({
		url: `${ENDPOINT}/google_login_active`,
		data,
		dataType: 'jsonp',
		success: (resp) => {
			if (resp.result === 'GOOGLE_INACTIVE') {
				window.open(`${ENDPOINT}/google_login_inactive?openIdConnectIss=${openIdConnectIss}&openIdConnectSub=${openIdConnectSub}`);
			} else {
				onSuccess(resp);
			}
		},
		error: onError,
		crossDomain: true,
	});
}

export function doMicrosoftLogin(opts) {
	const { onSuccess, onError } = opts;

	const openIdConnectIss = $('input#openidConnectIss').val();
	const openIdConnectSub = $('input#openidConnectSub').val();
	const openIdConnectUpn = $('input#openidConnectUpn').val();
	const data = { openIdConnectIss, openIdConnectSub, openIdConnectUpn };

	$.ajax({
		url: `${ENDPOINT}/microsoft_login_active`,
		data,
		dataType: 'jsonp',
		success: (resp) => {
			if (resp.result === 'MICROSOFT_INACTIVE') {
				window.open(
					`${ENDPOINT}/microsoft_login_inactive?openIdConnectIss=${openIdConnectIss}&openIdConnectSub=${openIdConnectSub}&openIdConnectUpn=${openIdConnectUpn}`
				);
			} else {
				onSuccess(resp);
			}
		},
		error: onError,
		crossDomain: true,
	});
}

export function doPasswordLogin(opts) {
	const { onSuccess, onError } = opts;
	const form = document.querySelector('.js-lockScreenForm');
	const data = getFormData(form);
	data.isOnboarding = isOnboardingUser();

	Ajax.post(`${ENDPOINT}/password_login`, data)
		.then((response) => onSuccess(response.data))
		.catch(onError);
}

export function doQuickBooksLogin() {
	window.open(`/sso/qbop_login`);
}

export function doSamlLogin() {
	window.open(`${ENDPOINT}/saml_login`);
}
