import React from 'react';
import { render } from 'base/wrapped-render';

import { BenchmarkingStatusUpdate } from './benchmarking-status-update';

const reactRoot = document.createElement('div');

document.body.appendChild(reactRoot);

export function renderBenchmarkingStatusUpdate() {
	render(
		<BenchmarkingStatusUpdate />,
		reactRoot
	);
}
