/*
 * Turns an html element (originally div) into a multi-item selector with a tag-like look and autocompletion
 *
 * takes an option object:
 * @param string options.autocompleteUrl: the location to query for autocomplete items
 * @param string options.description: the component's placeholder text before activation
 * @param function options.done: done function to run after plugin has been fully loaded + processed all starting items
 * @param function options.extraInit: init function to run after document ready + autoinputlist loaded
 * @param object options.extraParams: additional GET vars to send in autocomplete request to server
 * @param function options.formatAutoItem: function to render items in autocomplete list
 * @param function options.formatTagItem: function to render tag-like item after selected from auto-list
 * @param string options.inputClass: name of class to apply to input
 * @param array options.startingItems: items to pre-include in list; elements are passed through 'formatTagItem'
 * @param object options.autocompleteOptions: options to pass thru to the autocomplete plugin
 *
 * e.g..
 *
 * var input_list = $('.input-list').autoInputList({
 * 		autocompleteUrl: '/path/to/query',
 * 		description: 'start typing here',
 *		done: function() {},
 *		extraInit: function() {},
 *		extraParams: {'doSomethingAwesome': true},
 *		formatAutoItem: function(data) { return data },
 *		formatTagItem: function(data) { return data },
 *		inputClass: '.input',
 *		startingItems: [data1, data2],
 *		autocompleteOptions: {minChars: 4}
 * });
 *
 * // hide the description and focus the input
 * input_list.activate();
 *
 * // hide the input and show the description
 * input_list.deactivate();
 *
 * NOTE:
 * the autocomplete plugin has since been updated and merged into jqueryui; this plugin makes use of version 1.1 (deprecated)
 * http://bassistance.de/jquery-plugins/jquery-plugin-autocomplete/
 *
 */
(function () {
	$.fn.autoInputList = function (options) {
		var autocompleteObject = null;
		var selector = this.selector;
		// Are we being triggered from the new Share Modal ui-src/public/_components/share-modal.react/people-selector/people-selector.js
		var isShareModal = options.isShareModal || false;

		// merge defaults with options
		var settings = $.extend(
			{
				autoClose: true,
				autocompleteUrl: '/ajax/quicksearch.php',
				description: '',
				done: function () {},
				extraInit: function () {},
				extraParams: {},
				formatAutoItem: function (data, i, n, value) {
					if (data.length === 3) {
						return '<img src="' + data[2] + '" style="width:24px" /> ' + data[1];
					} else {
						return false;
					}
				},
				formatTagItem: function (event, data, formatted) {
					if (data) {
						if (typeof data === 'string') {
							data = data.split('|');
						}
						$(
							'<span class="item">' +
								xmlEntities(data[1]) +
								'<input type="hidden" name="eeids[]" value="' +
								data[0] +
								'" />' +
								'<a class="remove" href="javascript:void(0)"></a></span>'
						).insertBefore(selector + ' span.input');
						// clear input
						$('.free').val('');
						// remove item on 'x' click
						$(document).on('click', '.item .remove', function () {
							$(this).closest('span.item').remove();
							return false;
						});
					}
				},
				inputAttributes: {},
				inputClass: '.free',
				startingItems: [],
			},
			options
		);

		// activate/deactivate input list
		function activateInputList(val) {
			var $selector = $(selector);
			var $description = $selector.find('.description');
			var $inputs = $selector.find('.inputs');
			if (val) {
				$selector.removeClass('inactive');
				$description.hide();
				$inputs.show();
				const $input = $selector.find(settings.inputClass);
				if (!$input.is($(document.activeElement))) {
					$input.focus();
				}
			} else {
				$selector.addClass('inactive');
				$description.show();
				if (!isShareModal) {
					$inputs.hide();
				}
			}
		}

		/*
		 * autorun plugin
		 */
		$(function () {
			settings.extraInit();

			// autocomplete options
			var opts = $.extend(
				{
					delay: 50,
					cacheLength: 1,
					resultsClass: settings.resultsClass,
					selectFirst: true,
					matchSubset: false,
					max: 20,
					formatItem: settings.formatAutoItem,
					extraParams: settings.extraParams,
				},
				settings.autocompleteOptions
			);

			// create attribute string
			const attributeString =
				typeof settings?.inputAttributes === 'object'
					? Object.keys(settings?.inputAttributes || {})
							.map((key) => `${key}="${settings?.inputAttributes[key]}"`)
							.join(' ')
					: '';
			// append needed html
			$(selector).append(
				'<div class="description">' +
					settings.description +
					'</div><span class="inputs" style="display:none"><span class="input"><input type="text" class="' +
					settings.inputClass.substr(1) +
					'" value="" ' +
					attributeString +
					' /></span></span>'
			);
			// style element
			$(selector).css({ display: 'inline-block' });
			$(selector).addClass('inactive');
			// activate input
			$(selector).click(function () {
				if (!$(this).hasClass('disabled')) {
					activateInputList(true);
				}
			});
			$(selector + ' ' + settings.inputClass).click(function () {
				$(this).focus();
			});

			// text input
			autocompleteObject = $(settings.inputClass)
				.focus(function () {
					if (!$(this).hasClass('disabled')) {
						activateInputList(true);
					}
				})
				// backspace to remove last entry
				.keydown(function (e) {
					if (e.keyCode == 8 && this.value == '') {
						var l = $(this).closest(selector);
						var last = l.find('span.item:last');
						last.find('.remove').click();
					} else if (e.keyCode == 13) {
						e.preventDefault();
					}
				})
				.blur(function () {
					if (settings.autoClose) {
						setTimeout(function () {
							if ($(selector).find('.item').length == 0) {
								activateInputList(false);
							}
						});
					}
				})

				// try to autocomplete on keypress
				.autocomplete(settings.autocompleteUrl, opts)

				// add 'tag' from selected value
				.result(settings.formatTagItem);

			// add initial items to list
			if (settings.startingItems.length !== 0) {
				$.each(settings.startingItems, function (index, item) {
					settings.formatTagItem(null, item);
					activateInputList(true);
				});
			}

			settings.done();
		});

		/*
		 * external functions
		 */
		this.activate = function () {
			activateInputList(true);
			return this;
		};

		this.deactivate = function () {
			activateInputList(false);
			return this;
		};

		this.autocomplete = autocompleteObject;

		return this;
	};
})();
