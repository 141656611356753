import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';

const headerAnswersBtn = document.getElementById('js-headerAnswersBtn');

document.addEventListener('DOMContentLoaded', () => {
	if (headerAnswersBtn) {
		render(<PoMicroFrontend route='/answers/header-button' />, headerAnswersBtn);
	}
});
