import { search } from 'HelpCenter.mod';
import 'multi-factor-authentication.mod/manage-nav-mfa-options';

const DROPDOWN_OPEN_CLASS = 'ManageNavItemDropdown--open';
const NAV_BUTTON = 'js-manage-nav-button';
const NAV_BUTTON_OPEN_CLASS = 'SiteManageNav__item--open';
const NAV_IGNORE_CLICK = 'js-manage-nav-ignore';
const NAV_FOCUS = 'js-manage-nav-focus';

const NAV_ACTIONS = {
	search: () => { document.querySelector('.js-SearchBox-input').value = ''; search(''); }
};

export const initializeManageNav = () => {
	const NavItems = Array.from(document.querySelectorAll(`.${ NAV_BUTTON }`));

	NavItems.forEach(item => item.addEventListener('click', () => { toggleDropdown(item); }));

	document.addEventListener('click', clearDropdown);
};

const toggleDropdown = (button) => {
	const dropdown = button.nextElementSibling;

	clearDropdown();

	if (dropdown) {
		if (dropdown.classList.contains(DROPDOWN_OPEN_CLASS)) {
			dropdown.classList.remove(DROPDOWN_OPEN_CLASS);
			button.classList.remove(NAV_BUTTON_OPEN_CLASS);
		} else {
			dropdown.classList.add(DROPDOWN_OPEN_CLASS);
			button.classList.add(NAV_BUTTON_OPEN_CLASS);
			checkForFocus(dropdown);
			checkOnOpenAction(button);
		}
	}
};

const clearDropdown = () => {
	const dropdown = document.querySelector(`.${ DROPDOWN_OPEN_CLASS }`);

	if (dropdown) {
		const button = dropdown.previousElementSibling;
		const ignored = Array.from(document.querySelectorAll(`.${ NAV_IGNORE_CLICK }`));

		let isIgnored = false;

		if (ignored.length) {
			ignored.forEach((elem) => {
				if (elem.contains(event.target)) {
					isIgnored = true;
				}
			});
		}

		if (
			button &&
			!button.contains(event.target) &&
			!isIgnored
		) {
			dropdown.classList.remove(DROPDOWN_OPEN_CLASS);
			button.classList.remove(NAV_BUTTON_OPEN_CLASS);
		}
	}
};

const checkForFocus = (dropdown) => {
	const target = dropdown.querySelector(`.${ NAV_FOCUS }`);

	if (target) {
		target.focus();
	}
};

const checkOnOpenAction = (button) => {
	const action = button.dataset.onOpenAction;
	const param = button.dataset.onOpenActionParam;

	if (NAV_ACTIONS[action]) {
		NAV_ACTIONS[action](param);
	}
};

