import { isEnabled } from 'FeatureToggle.util';
import Sticky from 'Sticky.mod';
/*
 * Makes the nav element sticky.
 * Thanks @WaltWeidner for telling me how this is done.
 * @deprecated - This should no longer needed post Encore
 */
class StickyNav {

	constructor() {
		this.body = document.getElementsByTagName('body')[0];
		this.nav = document.getElementsByTagName('nav')[0];

		let isNavMain = (this.nav && this.nav.id === 'navMain');

		if (isEnabled('jade')) {
			this.nav = document.querySelector('.js-SiteHeader');
			isNavMain = true;
		}

		// Only finish instantiating if nav exists on page, and the id is navMain
		if (this.nav && isNavMain) {

			this.height = this.nav.offsetHeight;
			this.top = this.nav.offsetTop;
			this.parent = this.nav.parentNode;
			this.limited = false; // append the class fixedPageHeader
			if (this.body.classList.contains('fixedPageHeaderLimited')) {
				this.limited = true;
			}
			this.class = ''; // track the fixedPageHeader class

			// Create and append spacer node
			this.spacer = document.createElement('div');
			this.spacer.classList.add('navSpacer');
			this.spacer.style.display = 'none';
			this.spacer.style.height = `${ this.height }px`;
			this.parent.insertBefore(this.spacer, this.nav.nextSibling);

			// Add onScroll Event Listener
			window.addEventListener('scroll', this.onScrollStickyNav.bind(this));
			document.addEventListener('updateNavPosition', this.updateNavPosition.bind(this));

			this.onScrollStickyNav();
		}
	}

	/**
	 * Function to run on window.onScroll
	 */
	onScrollStickyNav() {
		if (window.pageYOffset > this.top) {
			this.stick();
		} else {
			this.unstick();
		}
	}

	stick() {
		this.nav.style.position = 'fixed';
		this.nav.style.top = '0';
		this.nav.style.zIndex = '1010';
		this.spacer.style.display = 'block';
		// prevent repeatedly firing the add class event
		if (!this.limited && !this.class) {
			this.class = 'fixedPageHeader';
			this.body.classList.add(this.class);
			this.addEvent(true);
		}
	}

	unstick() {
		this.nav.style.position = 'relative';
		this.nav.style.top = '';
		this.nav.style.zIndex = '';
		this.spacer.style.display = 'none';
		// prevent repeatedly firing the remove class event
		if (this.class == 'fixedPageHeader') {
			this.body.classList.remove(this.class);
			this.class = '';
			this.addEvent(false);
		}
	}

	// ugly but thank you IE
	addEvent(isSticky) {
		const event = document.createEvent('CustomEvent');
		event.initCustomEvent('navChanged', true, true, { isSticky });
		this.body.dispatchEvent(event);
	}

	updateNavPosition() {
		this.unstick();
		this.top = this.nav.offsetTop;
		this.onScrollStickyNav();
	}
}

if (isEnabled('wrappedStickyNav')) {
	const navElement = document.querySelector('.js-SiteHeader');

	if (navElement) {
		new Sticky(navElement, {
			onAfterStick: () => {
				navElement.style.zIndex = '1010';
				document.body.classList.add('fixedPageHeader');
			},
			onAfterUnstick: () => {
				navElement.style.zIndex = '';
				document.body.classList.remove('fixedPageHeader');
			},
		});
	}
} else {
	new StickyNav();
}
