import { useState } from 'react';

import { BenchmarkingModalOnSubmitService } from '../types';
import { benchmarkingCancelService } from '../services';

/**
 * Hook for managing state and side-effects of benchmarking modal
 * @param onSubmitService service that is hit on submit of modal
 * @returns 
 */
export const useBenchmarkingModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [onSubmitService, setOnSubmitService] = useState<BenchmarkingModalOnSubmitService>();
    return {
        isProcessing,
        isOpen,
        onSubmit: async () => {
            setIsProcessing(true);
            try {
                await onSubmitService();
                setIsOpen(false);
            } catch {
                setMessage($.__('Uh oh, something went wrong'), 'error');
            } finally {
                setIsProcessing(false);
            }
        },
        launch: (newOnSubmitService: BenchmarkingModalOnSubmitService) => {
            setIsOpen(true);
            setIsProcessing(false);
            setOnSubmitService(() => newOnSubmitService);
        },
        onRequestClose: async () => {
            setIsProcessing(true);
            try {
                await benchmarkingCancelService();
            } catch {
                setMessage($.__('Uh oh, something went wrong'), 'error');
            } finally {
                setIsProcessing(false);
                setIsOpen(false);
            }
        },
    };
}
