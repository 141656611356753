/**********************************************************

              .---. .---. 
             :     : o   :    me want cookie!
         _..-:   o :     :-.._    /
     .-''  '  `---' `---' "   ``-.    
   .'   "   '  "  .    "  . '  "  `.  
  :   '.---.,,.,...,.,.,.,..---.  ' ;
  `. " `.                     .' " .'
   `.  '`.                   .' ' .'
    `.    `-._           _.-' "  .'  .----.
      `. "    '"--...--"'  . ' .'  .'  o   `.
      .'`-._'    " .     " _.-'`. :       o  :
    .'      ```--.....--'''    ' `:_ o       :
  .'    "     '         "     "   ; `.;";";";'
 ;         '       "       '     . ; .' ; ; ;
;     '         '       '   "    .'      .-'
'  "     "   '      "           "    _.-'

via https://www.asciiart.eu/television/sesame-street

**********************************************************/
import cookies from 'browser-cookies';
import { IconV2, Flex, Headline, LayoutBox, BodyText, Button } from '@bamboohr/fabric';
import {ExternalLink, Message} from '@bamboohr/utils/lib/message';
import { ifFeature } from '@bamboohr/utils/lib/feature'; 
import Ajax from '@utils/ajax';
import { getParameterByName } from '@utils/url';

import './styles.styl';
import React from "react";

const USER = window.SESSION_USER;
const COOKIE_NAME = 'acceptCookies';
const COOKIE_VALUE = 'siteWideCookieAccepted';

// showCookeModal
export function showCookieModal() {
	const hasAcceptedCookies = cookies.get(COOKIE_NAME) === COOKIE_VALUE;
	const hasPhantomHash = getParameterByName('hash') && getParameterByName('u');

	if (
		!hasPhantomHash &&
		USER &&
		USER.acceptedCookies === false &&
		hasAcceptedCookies === false
	) {
		showModal();
	}
}

// @startCleanup encore
function addEventListener() {
	const btn = document.querySelector('.js-cookie-acceptance');

	if (btn) {
		try {
			btn.addEventListener('click', registerCookieAcceptance, true);
		} catch (error) {
			window.Rollbar.debug('Unable to add listener to node', error);
		}
	}
}
// @endCleanup encore

// registerCookieAcceptance
const registerCookieAcceptance = () => {

	let url = '/settings/users/accept_cookies';
	if (USER && USER.isOnboardingUser === true) {
		url = '/self_onboarding/packet/accept_cookies';
	}

	Ajax.post(url)
		.catch((error) => {
			cookies.set(COOKIE_NAME, COOKIE_VALUE, {
				domain: '.bamboohr.com',
				expires: 365 * 5, // 5 years
				path: '/',
				secure: true
			})
			window.Rollbar.error('Error when attempting to communicate cookie acceptance. Set cookie via the FE.', error)
		});

	// Close modal regardless (so the peeps don't get stuck)
	window.BambooHR.Modal.setState({ isOpen: false });

}

export function showModal() {
	window.BambooHR.Modal.setState({
		content: ifFeature('encore',
			<Flex alignItems='center' flexDirection='column' paddingBottom={6} paddingX={3} width='800px'>
				<IconV2 color='primary-strong' name='cookie-bite-regular' size={60} />
				<LayoutBox marginY={1.5}>
					<Headline component='h2' justify='center'>
						<Message text={$._("Surprise! We use cookies.")} />
					</Headline>
				</LayoutBox>
				<LayoutBox marginBottom={4} marginTop={0.5}>
					<BodyText color='neutral-strong' justify='center'>
						<Message link1={ ExternalLink('https://www.bamboohr.com/privacy-policy/') } text={$._(`When you are logged into the BambooHR application, we collect and store only cookies that are "strictly necessary" or "essential" to make the application function. \n 
						Read more in our [Privacy Policy.](1)`)} />
					</BodyText>
				</LayoutBox>
				<Button onClick={registerCookieAcceptance}>
					{ $.__('Okay, got it') }
				</Button>
			</Flex>,
			<div className="CookieModal">
				<ba-icon brand="true" class="CookieModal__icon" name="fab-cookie-54x54"></ba-icon>
				<h2 className="CookieModal__title">
					<Message text={$._("Surprise! We use cookies.")} />
				</h2>
				<span className="CookieModal__message">
					<Message link1={ ExternalLink('https://www.bamboohr.com/privacy-policy/') } text={$._(`When you are logged into the BambooHR application, we collect and store only cookies that are "strictly necessary" or "essential" to make the application function. \n 
					 Read more in our [Privacy Policy.](1)`)} />
				</span>
				<button className="fab-Button fab-button--large CookieModal__acceptance js-cookie-acceptance" type="button">
					{ $.__('Okay, got it') }
				</button>
			</div>
		),
		hasCloseButton: false,
		isHeadless: true,
		isOpen: true,
		// @startCleanup encore
		onOpen: () => addEventListener(),
		// @endCleanup encore
		type: 'custom'
	});
}

